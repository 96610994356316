import axios from "axios";
import DataTable from "react-data-table-component";
import React, { useDebugValue, useEffect, useState } from "react";
import Source from "../../Source";

export default function Stock() {
  const [records, setRecords] = useState([]);
  useEffect(() => {
    const fethStock = async () => {
      const token = localStorage.getItem("access");
      if (!token) return;
      const fetchWithToken = async (token) => {
        try {
          const response = await axios.get(
            Source.getAddress() + "/api/stock/",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(response.data);
          setRecords(response.data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            const refreshToken = localStorage.getItem("refresh");
            if (!refreshToken) return;

            try {
              const refreshResponse = await axios.post(
                Source.getAddress() + "/api/token/refresh/",
                {
                  refresh: refreshToken,
                }
              );
              const newAccessToken = refreshResponse.data.access;
              localStorage.setItem("access", newAccessToken);
              await fetchWithToken(newAccessToken);
            } catch (refreshError) {
              console.error("Error refreshing access token:", refreshError);
            }
          } else {
            console.error("Error fetching accounts:", error);
          }
        }
      };

      await fetchWithToken(token);
    };
    fethStock();
  });
//   dateInsert
// : 
// "2024-08-21"
// description
// : 
// ""
// id
// : 
// 1
// isdelete
// : 
// false
// item
// : 
// 1
// item_name
// : 
// "dell"
// item_type_name
// : 
// "computer"
// purchase_price
// : 
// 1000
// qty
// : 
// 10
// rate
// : 
// 100
// sell_price
// : 
// 2000
// user
// : 
// 2
// user_name
// : 
// "ahmad"
// weight
// : 
// 10
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    {
      name: "Photo",
      cell: (row) => (
        <img
          src={row.picture}
          alt={row.name}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Item Type",
      selector: (row) => row.item_type_name,
      sortable: true,
      style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    {
      name: "Date Creation",
      selector: (row) => row.dateInsert,
      sortable: true,
    },
    { name: "Description", selector: (row) => row.description, sortable: true },
    { name: "Purchase Price", selector: (row) => row.purchase_price, sortable: true },
    { name: "Sell Price", selector: (row) => row.sell_price, sortable: true },
    { name: "Add By", selector: (row) => row.user_name, sortable: true },
    { name: "Qty", selector: (row) => row.qty, sortable: true },
    { name: "Rate", selector: (row) => row.rate, sortable: true },
    {
      name: <p className="mt-3">Serial Number</p>,
      selector: (row) => row.serial_number,
      sortable: true,
      style: {
        width: "100px",
        minWidth: "100px",
      },
    },
  ];
  return (
    <div className={`w-100 ${"iransans"}`}>
      <div className="m-auto mt-5 m-5" style={{ height: "100%" ,width:"90%"}}>
        <button
          type="submit"
          className="btn btn-info mb-1 p-1"
          style={{ width: "100px" }}
        >
          Add
        </button>
        <div
          className="bg-light d-flex justify-content-lg-between"
          style={{ borderTop: "5px solid #4a5cf2" }}
        >
          <div className="d-flex w-100 h-100 m-auto justify-content-between">
            <h2 className="m-2 fw-bold mb-4">Stock</h2>
            <select
              id=""
              name="gender"
              //   onChange={ChangeUser}
              class="form-select h-50 m-auto"
              style={{ width: "20%" }}
              //   value={select_user}
            >
              <option value={"all users"} selected>
                All Users
              </option>
              {/* {user.map((item) =>(
              <option value={item.name} selected>{item.name}</option>
              ))} */}
            </select>
          </div>
          <input
            className="form-control m-2 mb-4"
            style={{ width: "20%" }}
            // onChange={handleFilter}
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </div>

        <DataTable
          // title="Customers"
          columns={columns}
          data={records}
          // onRowClicked={handleRowClick}
          striped
          responsive
          highlightOnHover
          pagination
          // noHeader
          // noTableHead
        />
        {/* {
          <AccountEdit
          loading={loading}
          setLoading={setLoading}
          inputRef={inputRef1}
            close={() => setEdit(false)}
            account={editAccount1}
            setAccount={setEditAccount1}
            // records={records}
            // setRecords={setRecords}
            edit={edit}
            records={records}
            setRecords={setRecords}
          />
        }
        <Belance
        setMoney={setMoney}
        inputRef={inputRefbelance}
        belancem={belance}
         setbelance={setbelance}
        prof={prof}
        idprof={idprof}
        customer={customer}
        setcustomer={setcustomer}
        moneyp={money}
        loading={loading}
        setLoading={setLoading}
          close={() => setOpenBelance(false)}
          id={id}
          accountbelance={editAccount}
          setEditAccount={setEditAccount}
          openBelance={openBelance}
          setOpenBelance={setOpenBelance}
        />
        {
          <AddAccount
          loading={loading}
          setLoading={setLoading}
            inputRef={inputRef}
            close={() => setAddAccountModal(false)}
            addAccountModal={addAccountModal}
            records={records}
            setRecords={setRecords}
          />
        } */}
      </div>
    </div>
  );
}
