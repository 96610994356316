import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import AccountEdit from "./CustomersEdit";
import Datepicker from "./forms/Datepicker";
import Add from "../assets/icon/add.png";
import Belance from "./forms/Belance";
import AddAccount from "./AddAccount";
import Trash from "../assets/icon/trash.png";
import Swal from "sweetalert2";
import Source from "../Source";
import { gregorianToJalali } from "shamsi-date-converter";
import jalaali from "jalaali-js";
import ComboBox from "./forms/ComboBox";
import moment from "moment-jalaali";
import Datepicker_Customer from "./forms/Datepicker_customer";
import { NumericFormat } from "react-number-format";
import { useRef } from "react";
import Edit_withdraw from "./Edit_withdraw";
const formatNumber = (number) => {
  return number.toLocaleString(); // Formats number with thousand separators
};
export default function Withdraw({
  
  user,
  settings,
  
}) {
  const [withdraw,setwithdraw]=useState([]);
  const [belance,setbelance]=useState([]);
  const [Cash,setCash]=useState([]);

  const [Diversity, setDiversity] = useState();
  const [selectedDayE, setSelectedDayE] = useState();
  const [selectedDay, setSelectedDay] = useState(moment());
  const [EndDateE, setEndDateE] = useState(null);
  const [OpenEdit, setOpenEdit] = useState(false);
  const [EditDeposit, setEditDeposit] = useState({
    id: 0,
    account: 0,
    date: "",
    amount: 0,
    discription: "",
    type: "",
    customer: "",
    moneyType: "",
    cash: 0,
    isdelete: false,
    user: 0,
    user_name: "",
    moneyid: 0,
  });
  const handle_date = (jalaliDate) => {
    // console.log(jalaliDate);
    if (jalaliDate) {
      const { year, month, day } = jalaliDate;
      const gregorianDate = jalaali.toGregorian(year, month, day);
      // Get current time
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const t = new Date();
      const date = new Date(
        jalaliDate.year,
        jalaliDate.month - 1,
        jalaliDate.day,
        t.getHours(),
        t.getMinutes()
      );

      const isoString = date.toISOString(); // This gives you the ISO string in UTC
      // setIsoDate(isoString);
      setAddDeposite({
        ...AddDeposite,
        date: isoString,
      });
      // const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}`;
    }
  };

  const [moneytype, setmoneytype] = useState(0);
  const [money1, setmoney] = useState({ name: "", cach: 0 });
  const [records, setRecords] = useState();
  // const [belance, setBelance] = useState([]);
  const [openBelance, setOpenBelance] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editAccount, setEditAccount] = useState(null);
  const [id, setId] = useState("");
  const [idbelance, setidbelance] = useState(0);
  const [value, setValue] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [Popup, setPopup] = useState(false);

  const [username, setusername] = useState("");
  const [moneyname, setmoneyname] = useState("");
  const [addAccountModal, setAddAccountModal] = useState(false);
  const onChange = (row) => {
    setPopup(true);
    setValue(row.target.value);
  };
  const [selectedBelance, setselectedBelance] = useState({
    id: 0,
    account_name: "",
    moneyType: "",
    account: 0,
    moneyId: 0,
    user: 0,
    type: 0,
    belance: 0,
    date_created: "",
  });

  const filterDeposite = () => {
    // console.log(StartDate);
    const startDate = new Date(
      `${StartDate.year}-${StartDate.month}-${StartDate.day}`
    ); // Start date
    const endDate = new Date(`${EndDate.year}-${EndDate.month}-${EndDate.day}`); // End date
    endDate.setDate(endDate.getDate() + 2);
    const type = "withdraw";
    axios
      .get(Source.getAddress() + "/api/report/", {
        params: {
          startDate: startDate,
          endDate: endDate,
          type: type,
          delete: "False",
        },headers: {
          'Authorization': `Bearer ${localStorage.getItem('access')}`
  }
      })
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Deposite Table filterd successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        setRecords(res.data); // This will contain filtered data from the server
        // console.log(res.data);
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something went wrong !",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };
  const [formattedValue, setFormattedValue] = useState("");
  const [unformattedValue, setUnformattedValue] = useState("");

  const handleChange = (e) => {
    const formattedValue = e.target.value;
    // Remove the thousand separators (commas)
    const unformattedValue = formattedValue.replace(/,/g, "").replace("$", "");
    setFormattedValue(formattedValue);
    setAddDeposite({
      ...AddDeposite,
      amount: unformattedValue,
      user: localStorage.getItem("userTokenid"),
    });
  };
  const handleDeposite = (e) => {
    setAddDeposite({
      ...AddDeposite,
      [e.target.name]: e.target.value,
    });
  };
  const Onsearch = (row) => {
    if (row) {
      setusername(row.account_name);
      setmoneyname(row.moneyType);
      setmoneytype(row.moneyId);
      setidbelance(row.id);
      setselectedBelance((prevState) => ({
        ...prevState,
        id: row.id,
        moneyId: row.moneyId,
        user: row.user,
        moneyType: row.moneyType,
        account: row.account,
        account_name: row.account_name,
        type: row.type,
        belance: row.belance, // Assuming 'belance' is correct, it seems like a typo
        date_created: row.date_created,
      }));
      let belanceName = row.account_name + " " + row.moneyType;
      setAddDeposite({
        ...AddDeposite,
        account: row.id,
      });
      setPopup(false);
      setValue(belanceName);
    }
  };

  const [AddDeposite, setAddDeposite] = useState({
    user: 0,
    isdelete: "False",
    discription: "",
    amount: 0,
    date: null,
    account: 0,
    type: "withdraw",
    cash: 0,
  });

  const primary = () => {
    // const record= Cash.filter((item)=>{item.id==moneytype});
    if (selectedBelance.belance === "0") {
      return -parseInt(AddDeposite.amount);
    } else {
      let money = parseInt(AddDeposite.amount);
      let primar = parseInt(selectedBelance.belance);
      let sum = primar - money;
      return sum;
    }
  };

  const updatemoney = () => {
    const record = Cash.find((item) => item.id === moneytype);
    if (record) {
      let money = parseInt(AddDeposite.amount);
      let primmoney = parseInt(record.cach);
      let sum = primmoney - money;
      return { name: record.name, cash: sum, user: record.user };
    } else {
      // Handle the case when record is undefined
      return { name: "", cash: 0 };
    }
  };

  const fetchLastReportId = () => {
    const response = axios.get(
      `${Source.getAddress()}/api/report/get_last_report_id/`
    );
    return response.data.last_report_id || 1;
  };

  const SumbitReport = () => {

    if (AddDeposite.amount !== 0 && AddDeposite.account !== 0) {
      let dd = new Date();
      let pri = primary();
      // setselectedBelance({
      //   ...selectedBelance,
      //   belance: pri,
      // });
      const moneyinsert = updatemoney();
      const uploadData = new FormData();
      const uploadDatamoney = new FormData();
      uploadDatamoney.append("name", moneyinsert.name);
      uploadDatamoney.append("cach", moneyinsert.cash);
      uploadDatamoney.append("isdelete", "False");
      uploadDatamoney.append("user", moneyinsert.user);
      uploadDatamoney.append('ontransaction','True');
      uploadData.append("type", selectedBelance.type);
      uploadData.append("belance", pri);
      uploadData.append('ontransaction','True');
      uploadData.append("account", selectedBelance.account);
      uploadData.append("date_created", selectedBelance.date_created);
      uploadData.append("user", selectedBelance.user);
      uploadData.append("isdelete", "False");
      let add_deposite = {
        user: 0,
        discription: "",
        amount: 0,
        date: null,
        account: 0,
        type: "deposite",
        cash: 0,
        isdelete: "False",
      };
      add_deposite.user = AddDeposite.user;
      add_deposite.amount = AddDeposite.amount;
      add_deposite.discription = AddDeposite.discription;
      add_deposite.date = AddDeposite.date;
      add_deposite.account = AddDeposite.account;
      add_deposite.type = AddDeposite.type;
      add_deposite.cash = moneyinsert.cash;
      add_deposite.isdelete = AddDeposite.isdelete;
      axios
        .post(`${Source.getAddress()}/api/report/`, add_deposite,{ headers: {
          'Authorization': `Bearer ${localStorage.getItem('access')}`,
        },})
        .then((response) => {
          let add = {
            id: 0,
            user_name: "",
            moneyType: "",
            customer: "",
            user: 0,
            discription: "",
            amount: 0,
            date: null,
            account: 0,
            type: "deposite",
            cash: 0,
            isdelete: "False",
            account: 0,
            moneyId:0,
          };
          add.id = response.data.new_account_id;
          fetch(Source.getAddress() + "/api/belance/" + `${idbelance}/`, {
            method: "PUT",
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
              // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
            },
            body: uploadData,
          })
            .then((res) => {
              fetch(Source.getAddress() + "/api/money/" + `${moneytype}/`, {
                method: "PUT",
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
                  // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
                },
                body: uploadDatamoney,
              })
                .then((res) => {
                  add.account = AddDeposite.account;
                  add.user = localStorage.getItem("userTokenid");
                  add.discription = AddDeposite.discription;
                  add.amount = AddDeposite.amount;
                  add.date = AddDeposite.date;
                  add.account = AddDeposite.account;
                  add.type = AddDeposite.type;
                  add.cash = moneyinsert.cash;
                  add.isdelete = AddDeposite.isdelete;
                  add.customer = username;
                  add.user_name = localStorage.getItem("userToken");
                  add.moneyType = moneyname;
                  setRecords(prevRecords => [add, ...prevRecords]);
                  setSelectedDay(moment());
                  let datetime = new Date();
                  // console.log();
                  // const handl={year:date._a[0],month:date._a[1]+1,day:date._a[2]}
                  const jalaliDate12 = {
                    year: datetime.getFullYear(),
                    month: datetime.getMonth() + 1,
                    day: datetime.getDay(),
                  };
                  const { year, month, day } = jalaliDate12;
                  // Create the ISO date string
                  const t = new Date();
                  const date = new Date();
                  const isoString = date.toISOString();
                  setAddDeposite({
                    user: localStorage.getItem("userTokenid"),
                    isdelete: "False",
                    discription: "",
                    amount: 0,
                    date: isoString,
                    account: 0,
                    type: "withdraw",
                    cash: 0,
                  });
                  setFormattedValue("");
                  setSelectedOption("");
                  let add_cash = {
                    id: 0,
                    account_name: "",
                    moneyType: "",
                    account: 0,
                    moneyId: 0,
                    type: 0,
                    belance: 0,
                    date_created: "",
                    user: 0,
                  };
                  add_cash.user = selectedBelance.user;
                  add_cash.id = selectedBelance.id;
                  add_cash.account_name = selectedBelance.account_name;
                  add_cash.moneyId = selectedBelance.moneyId;
                  add_cash.moneyType = selectedBelance.moneyType;
                  add_cash.account = selectedBelance.account;
                  add_cash.type = selectedBelance.type;
                  add_cash.belance = pri;
                  add_cash.date_created = selectedBelance.date_created;
                  setbelance(prevBelance =>
                    prevBelance.map(item => (item.id === add_cash.id ? add_cash : item))
                  );
                  
                  let update_money = {
                    name: "sefide",
                    user: 1,
                    existense: false,
                    cach: 0,
                    user_name: "",
                    id: 0,
                  };
                  update_money.name = moneyinsert.name;
                  update_money.id = moneytype;
                  update_money.cach = moneyinsert.cash;
                  setCash(prevcash=>
                    prevcash.map((a) => (a.id === moneytype ? update_money : a))
                  );
                  Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Your record has been added !",
                    showConfirmButton: false,
                    timer: 1000,
                  });
                })
                .catch((err) => {
                  Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Your cache not updated !",
                    showConfirmButton: false,
                    timer: 1000,
                  });
                });
            })
            .catch((error) => {
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Account and cache not updated !",
                showConfirmButton: false,
                timer: 1000,
              });
            });
        })
        .catch((error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Not working ,please try again !",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "You must fill Customer name and Amount input !",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const [addreport, setaddreport] = useState({
    discription: "",
    amount: 0,
    date: "",
    account: 0,
  });
  const res = useMemo((result) => {
    if (window.innerWidth > 768) {
      return true;
    } else {
      return false;
    }
  });
  const delete_report = async (row) => {
    setOpenEdit(false)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
  
    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });
  
    if (result.isConfirmed) {
      try {
        let amount_delete = parseInt(row.amount);
        let belacne_delete_primary_response = await axios.get(
          Source.getAddress() + `/api/belance/${row.account}/`,{ headers: {
            'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
            // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
          },}
        );
  
        const belacne_delete_primary = belacne_delete_primary_response.data;
        let belance_amount = parseInt(belacne_delete_primary.belance);
        let belance_delete = {
          account: belacne_delete_primary.account,
          type: belacne_delete_primary.type,
          belance: belance_amount + amount_delete,
          date_created: belacne_delete_primary.date_created,
          user: belacne_delete_primary.user,
          isdelete: "False",
        };
  
        let money_delete_primary_response = await axios.get(
          Source.getAddress() + `/api/money/${belacne_delete_primary.moneyId}/`,{ headers: {
            'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
            // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
          },}
        );
  
        const money_delete_primary = money_delete_primary_response.data;
        let cash_primary = parseInt(money_delete_primary.cach);
        let money_delete = {
          name: money_delete_primary.name,
          cach: cash_primary + amount_delete,
          existense: "False",
          user: money_delete_primary.user,
        };
  
        await axios.put(
          Source.getAddress() + `/api/belance/${belacne_delete_primary.id}/`,
          belance_delete,{ headers: {
            'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
            // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
          },}
        );
  
        belacne_delete_primary.belance = belance_delete.belance;
        belacne_delete_primary.isdelete = belance_delete.isdelete;
  
        setbelance((prevBelance) =>
          prevBelance.map((a) =>
            a.id === belacne_delete_primary.id ? belacne_delete_primary : a
          )
        );
  
        // Update the money
        await axios.put(
          Source.getAddress() + `/api/money/${money_delete_primary.id}/`,
          money_delete,{ headers: {
            'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
            // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
          },}
        );
  
        money_delete_primary.cach = money_delete.cach;
        setCash((prevCash) =>
          prevCash.map((a) =>
            a.id === money_delete_primary.id ? money_delete_primary : a
          )
        );
  
        // Update the report to mark it as deleted
        let update = {
          account: row.account,
          date: row.date,
          amount: row.amount,
          discription: row.discription,
          type: row.type,
          cash: cash_primary + amount_delete,
          isdelete: "True",
          user: localStorage.getItem("userTokenid"),
        };
  
        await axios.put(Source.getAddress() + `/api/report/${row.id}/`, update,{ headers: {
          'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
          // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
        },});
  
        setRecords((prevRecords) =>
          prevRecords.filter((record) => record.id !== row.id)
        );
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Withdraw record successfully deleted !",
          showConfirmButton: false,
          timer: 600,
        });
      } catch (err) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Withdraw record not deleted",
          showConfirmButton: false,
          timer: 600,
        });
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "Your Withdraw record is safe :)",
        showConfirmButton: false,
        timer: 600,
      });
    }
  };
  const convertToKabulTime = (dateStr) => {
    const dateObj = new Date(dateStr);

    // Set the time zone to Kabul (UTC+4:30)
    dateObj.setMinutes(dateObj.getMinutes() + 540); // Kabul is UTC+4:30

    // Format the date object into the desired string format
    const formattedDateStr = dateObj.toISOString().slice(0, 16);

    return formattedDateStr;
  };
  useEffect(()=>{
    axios.get(Source.getAddress() + '/api/money/', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 'False',
      },
    }).then((res)=>{
      setCash(res.data);
    }).catch((err)=>{
      console.log(err);
    });
  },[]);
  useEffect(()=>{
    axios.get(Source.getAddress() + '/api/belance/', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 'False',
      },
    }).then((res)=>{
      setbelance(res.data);
    }).catch((err)=>{
      console.log(err);
    });
   },[]); 
 
  useEffect(() => {
    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 30);
    endDate.setDate(endDate.getDate() + 1);
    axios.get(Source.getAddress() + '/api/report/', {
    headers:{
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
    params: {
      delete:'False',
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(), 
      type:'deposite'
    },
    }).then((res)=>{
    setRecords(res.data);
    setwithdraw(res.data);
    }).catch((err)=>{
    console.log(err); 
    })



    let datetime = new Date();
    const jalaliDate12 = {
      year: datetime.getFullYear(),
      month: datetime.getMonth() + 1,
      day: datetime.getDay(),
    };
    const { year, month, day } = jalaliDate12;
    const date = new Date();
    const isoString = date.toISOString();
    let news = new Date();
    let mmm = news.toISOString().slice(0, 16);
    let to = convertToKabulTime(mmm);
    setAddDeposite({
      ...AddDeposite,
      date: isoString,
      user: localStorage.getItem("userTokenid"),
    });
    setEndDate(
      `${news.getFullYear()}-${String(news.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(news.getDate()).padStart(2, "0")}`
    );
    news.setDate(news.getDate() - 1);
    setStartDate(
      `${news.getFullYear()}-${String(news.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(news.getDate()).padStart(2, "0")}`
    );
  }, [withdraw]);
  const [account, setAccount] = useState();
  // const [selectedDayE, setSelectedDayE] = useState();
  const [selectedOption1, setSelectedOption1] = useState();
  const inputRef=useRef();

  const handleRowClick = (row) => {
    inputRef.current.select();
    setAccount(row.account);
    let edit = {
      account_name: row.customer,
      moneyType: row.moneyType,
    };
    setSelectedOption1(edit);
    setDiversity(row.amount);
    let news;
    try {
      news = new Date(row.date);
      if (isNaN(news.getTime())) {
        throw new Error("Invalid date");
      }
    } catch (error) {
      console.error('Invalid date format:', row.date);
      return;
    }
    // console.log('Parsed date (news):', news);

    news.setDate(news.getDate());
    const jalaliDate = jalaali.toJalaali(
      news.getFullYear(),
      news.getMonth() + 1,
      news.getDate()
    );
const gregorianMoment = moment(news);
    setSelectedDayE(gregorianMoment);
    setEditAccount(row);
    setEdit(true);
    setEditDeposit(row);
    // setOpenEdit(true);
    setOpenEdit(prev=>!prev);
  };
  const convertToHijriShamsi = (gregorianDate) => {
    // Convert Gregorian date to Hijri Shamsi
    // const gregorianDate = "2000-11-11";
    const [jalaliYear, jalaliMonth, jalaliDay] = gregorianToJalali(
      new Date(gregorianDate)
    );

    const hijriShamsiDate = `${jalaliYear}/${jalaliMonth}/${jalaliDay}`;

    return hijriShamsiDate;
  };
  const date = (d) => {
    const date = new Date(d);

    let formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    let aa = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    if (localStorage.getItem('date')=== "Persian") {
      aa = hours >= 12 ? "ق.ظ" : "ب.ظ";
      formattedDate = convertToHijriShamsi(
        `${formattedDate}  ${formattedHours}:${formattedMinutes} ${ampm}`
      );
    }
    return `${formattedDate}  ${formattedHours}:${formattedMinutes} ${aa}`;
  };
  // const time=(d)=>{
  //   const time = new Date(d);
  //   const hours = time.getHours();
  //   const minutes = time.getMinutes();
  //   const ampm = hours >= 12 ? 'pm' : 'am';
  //   const formattedHours = hours % 12 || 12;
  //   const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  //   return `${formattedHours}:${formattedMinutes} ${ampm}`;
  // }

  const handleViewBelance = (row) => {
    const filteredBelance = belance.filter((item) => item.account === row.id);

    setEditAccount(filteredBelance);
    setId(row.id);
    setOpenBelance(true);
  };

  // const [records, setRecords] = useState(rows);

  // const handleFilter = (e) => {
  //   // console.log(accounts);
  //   const newData = withdraw.filter((row) => {
  //     if (typeof row.customer === "string") {
  //       return row.customer
  //         .toLowerCase()
  //         .includes(e.target.value.toLowerCase());
  //     }
  //     return false;
  //   });
  //   setRecords(newData);
  // };

  const handleFilter = (e) => {
    // console.log(accounts);
    const newData = withdraw.filter((row) => {
      if (typeof row.customer === "string") {
        if (
          row.customer.toLowerCase().includes(e.target.value.toLowerCase()) ||
          row.moneyType.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          return row.customer;
        }
      }
      return false;
    });
    setRecords(newData);
  };

  // const date=(d)=>{
  //   const date = new Date(d);

  //   let formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  //   const hours = date.getHours();
  //   const minutes = date.getMinutes();
  //   const ampm = hours >= 12 ? 'pm' : 'am';
  //   const formattedHours = hours % 12 || 12;
  //   const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  //   if(settings[0].date==='Persian'){
  //     // console.log('hi')
  //     formattedDate=  convertToHijriShamsi( `${formattedDate}  ${formattedHours}:${formattedMinutes} ${ampm}`);
  //   }
  //   return `${formattedDate}  ${formattedHours}:${formattedMinutes} ${ampm}`;
  // }
  const handleAddAccount = () => {
    setAddAccountModal(true);
  };

  const columnsDesktop = [
    {
      name: <strong style={{ width: "1px" }}>ID</strong>,
      selector: (row) => row.id,
      //  sortable: true
      style: {
        width: "1px",
        minWidth: "10px",
      },
    },

    {
      name: <strong>Customer Name</strong>,
      selector: (row) => row.customer,
      style: {
        padding: "0px 20px",
        justifyContent: "left",
        textAlign: "center",
      },
      // sortable: true
    },
    {
      name: (
        <strong
          style={{ minWidth: "170px", maxWidth: "210px", width: "210px" }}
        >
          Date Created
        </strong>
      ),
      selector: (row) => date(row.date),
      style: {
        minWidth: "170px", // Adjust this value as needed
        maxWidth: "200px", // Adjust this value as needed
        // Width: '600px',    // Set a specific width
      },
      // sortable: true,
    },
    // { name: <strong>Time Created</strong>, selector: (row) => time(row.date_created),
    // //  sortable: true
    // },
    {
      name: <strong>Money Type</strong>,
      selector: (row) => row.moneyType,
      //  sortable: true
    },
    {
      name: <strong className="w-50 text-center">Amount</strong>,
      selector: (row) => formatNumber(row.amount),
      //  sortable: true
      style: {
        textAlign: "center",
        // minWidth:'100px',
      },
    },
    {
      name: <strong>Description</strong>,
      selector: (row) => row.discription,
    },
    // {
    //   name: <strong>Cash Belance</strong>,
    //   selector: (row) => formatNumber(row.cash),
    // },
    {
      name: <strong>Added by </strong>,
      selector: (row) => row.user_name,
    },
    {
      name: (
        <strong
          style={{
            textAlign: "center",
            backgroundColor: "tranceparent",
            width: "100%",
          }}
        >
          Delete
        </strong>
      ),
      selector: (row) => (
        <button
          onClick={() => {
            delete_report(row);
            // setEdit(row);
            // setUpdate(true);
            // seTtitle('Edit Type Money');
          }}
          style={{
            border: "none",
            backgroundColor: "transparent",
            height: "100%",
          }}
        >
          <img
            height={"15%"}
            width={"15%"}
            src={Trash}
            style={{ backgroundColor: "tranceparent" }}
          />
        </button>
      ),
    },
  ];

  const columnsDesktopsimple = [
    {
      name: <strong style={{ width: "1px" }}>ID</strong>,
      selector: (row) => row.id,
      //  sortable: true
      style: {
        width: "1px",
        minWidth: "10px",
      },
    },

    {
      name: <strong>Customer Name</strong>,
      selector: (row) => row.customer,
      style: {
        padding: "0px 20px",
        justifyContent: "left",
        textAlign: "center",
      },
      // sortable: true
    },
    {
      name: (
        <strong
          style={{ minWidth: "170px", maxWidth: "200px", width: "200px" }}
        >
          Date Created
        </strong>
      ),
      selector: (row) => date(row.date),
      style: {
        minWidth: "170px", // Adjust this value as needed
        maxWidth: "200px", // Adjust this value as needed
        // Width: '600px',    // Set a specific width
      },
      // sortable: true,
    },
    // { name: <strong>Time Created</strong>, selector: (row) => time(row.date_created),
    // //  sortable: true
    // },
    {
      name: <strong>Money Type</strong>,
      selector: (row) => row.moneyType,
      //  sortable: true
    },
    {
      name: <strong className="w-50 text-center">Amount</strong>,
      selector: (row) => formatNumber(row.amount),
      //  sortable: true
      style: {
        textAlign: "center",
        // minWidth:'100px',
      },
    },
    {
      name: <strong>Description</strong>,
      selector: (row) => row.discription,
    },
    {
      name: <strong>Cash Belance</strong>,
      selector: (row) => formatNumber(row.cash),
    },
  ];

  const columnsTablet = [
    // { name: "Id", selector: (row) => row.id, sortable: true },

    { name: "account_name", selector: (row) => row.customer, sortable: true },
    ,
    { name: "moneyType", selector: (row) => row.moneyType, sortable: true },
    {
      name: "belance",
      selector: (row) => formatNumber(row.cash),
      sortable: true,
    },
  ];
  const [select_user, setselect_user] = useState("");
  const ChangeUser = (e) => {
    if (e.target.value !== "all users") {
      const newData = withdraw.filter((row) => {
        if (typeof row.user_name === "string") {
          return row.user_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        return false;
      });
      setRecords(newData);
    } else {
      setRecords(withdraw);
    }
    setselect_user(e.target.value);
  };
  const [selectedOption, setSelectedOption] = useState();

  const buttonRef = useRef(null);
  const filterRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // Check if the Enter key was pressed
      e.preventDefault(); // Prevent default action if needed
      if (buttonRef.current) {
        buttonRef.current.click(); // Programmatically click the button
      }
    }
  };

  const Hanlderef = (e) => {
    if (e.key === "Enter") {
      // Check if the Enter key was pressed
      e.preventDefault(); // Prevent default action if needed
      if (filterRef.current) {
        filterRef.current.click(); // Programmatically click the button
      }
    }
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const columns = useMemo(() => {
    if (isDesktop) {
      return localStorage.getItem("userTokenname") === "admin"
        ? columnsDesktop
        : columnsDesktopsimple;
    }
    return columnsTablet;
  }, [isDesktop]);

  const inputRefd = useRef(null); // Create a ref for the input field

  useEffect(() => {
    const inputElement = document.querySelector(".form-control"); // Use the class or other selector
    if (inputElement) {
      setTimeout(() => {
        inputElement.focus(); // Focus the input field
        inputElement.select(); // Select the text in the input field
      }, 0);
    }
  }, []);

  return (
    <div
      className="container mt-5 h-100 w-100"
      onClick={(e) => {
        if (
          e.target.className === "container mt-5 h-100 w-100" ||
          e.target.className ===
            "col-12 w-100 row rounded-3 m-3 pe-5 p-1 bg-transparent" ||
          e.target.className ===
            "col-12 w-100 row rounded-3 m-3 pe-5 p-1 bg-transparent "
        ) {
          setPopup(false);
          setOpenEdit(false);
        }
      }}
    >
      <form class="row w-100 rounded-3 m-3 p-1 bg-transparent">
        <div class="col-lg-2 col-md-3 col-sm-6 m-1 mt-3">
          <label for="category">Customer</label>
          <ComboBox
            options={belance}
            Onsearch={Onsearch}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 m-0 mb-3 mt-3 ms-3 p-0 pe-4">
          <label for="price">Amount</label>
          <NumericFormat
            ref={inputRefd}
            thousandSeparator={true}
            name="amount"
            className="form-control"
            value={formattedValue}
            onKeyDown={handleKeyDown}
            placeholder="0"
            onChange={handleChange}
          />
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 m-2 mt-3 ps-2 p-0 pe-3">
          <div class="form-floating">
            <textarea
              onKeyDown={handleKeyDown}
              name="discription"
              class="form-control h-50"
              placeholder="Leave a comment here"
              id="floatingTextarea2"
              value={AddDeposite.discription}
              onChange={handleDeposite}
            ></textarea>
            <label for="floatingTextarea2">Discription</label>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12  mt-3 ps-3 p-0">
          <Datepicker_Customer
            onKeyDown={handleKeyDown}
            default_value={EndDate}
            settings={settings}
            handle_date={handle_date}
            lebal={"Date"}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
          ></Datepicker_Customer>
        </div>
        <div
          class="col-lg-1 col-md-3 col-sm-6 p-0 ps-0 ms-0"
          style={{ marginTop: "2.3rem" }}
        >
          <button
            ref={buttonRef}
            onClick={SumbitReport}
            type="button"
            class="btn btn-success px-5"
          >
            ADD
          </button>
        </div>
      </form>

      <div
        className="bg-light d-flex flex-column flex-lg-row justify-content-lg-between align-items-start align-items-lg-center"
        style={{ borderTop: "5px solid #4a5cf2" }}
      >
        <h2 className="m-2 fw-bold mb-4">Withdraw</h2>
        <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
          <div className="mb-2 mb-lg-0 me-lg-2">
            <Datepicker
              default_value={StartDate}
              settings={settings}
              handle_date={(e) => setStartDate(e)}
              lebal={"Start"}
              onKeyDown={Hanlderef}
            />
          </div>
          <div className="mb-2 mb-lg-0 me-lg-2">
            <Datepicker
              onKeyDown={Hanlderef}
              default_value={EndDate}
              settings={settings}
              handle_date={(e) => setEndDate(e)}
              lebal={"End"}
            />
          </div>
          <button
            ref={filterRef}
            onClick={() => {
              filterDeposite();
            }}
            type="button"
            className="btn btn-success mb-2 mb-lg-0 w-100 w-lg-50 h-50"
            style={{ marginTop: "2rem" }}
          >
            Filter
          </button>
          <select
            id=""
            name="gender"
            className="form-select mb-2 mb-lg-0 ms-lg-5 mt-4"
            style={{ width: "100%", maxWidth: "200px" }}
            value={select_user}
            onChange={ChangeUser}
          >
            <option value={"all users"} selected>
              All Users
            </option>
            {user.map((item) => (
              <option value={item.name} key={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <input
          className="form-control m-2 mb-2 mt-4"
          style={{ width: "100%", maxWidth: "200px" }}
          type="search"
          onChange={handleFilter}
          placeholder="Search"
          aria-label="Search"
        />
      </div>
      <DataTable
        className="w-100"
        columns={columns}
        data={records}
        onRowClicked={handleRowClick}
        striped
        responsive
        highlightOnHover
        pagination
      />
      <Edit_withdraw
       inputRef={inputRef}
       setSelectedOption={setSelectedOption1}
       selectedOption={selectedOption1}
       records={records}
       setRecords={setRecords}
       handleKeyDown={handleKeyDown}
       setCash={setCash}
       Cash={Cash}
       belance={belance}
       setbelance={setbelance}
       account={account}
       setAccount={setAccount}
       Diversity={Diversity}
       setDiversity={setDiversity}
       selectedDay={selectedDayE}
       setselectedDay={setSelectedDayE}
       EndDate={EndDateE}
       Edit_Deposit={EditDeposit}
       setEditDeposit={setEditDeposit}
       settings={settings}
       handle_date={handle_date}
       setSelectedDay={setSelectedDayE}
       open={OpenEdit}
       closeEdit={() => setOpenEdit(false)}
      />
      {/* {edit && (
        <AccountEdit
          close={() => setEdit(false)}
          account={editAccount}
          setAccount={setEditAccount}
        />
      )} */}

      {/* <img style={{height:'6%',width:'6%'}} className="p-0" src={Add}/> */}
      {openBelance && (
        <Belance
          close={() => setOpenBelance(false)}
          id={id}
          accountbelance={editAccount}
        />
      )}
      {addAccountModal && (
        <AddAccount close={() => setAddAccountModal(false)} />
      )}
    </div>
  );
}