import React, { useEffect, useRef, useState } from "react";
import Source from "../../Source";
import axios from "axios";
import Swal from "sweetalert2";
import Profile from "../../assets/icon/profile.png";
import IdNational from "../../assets/icon/national_id.png";
import CompanyPic from '../../assets/icon/company.png'

export default function AddInfo({
  close,
  addAccountModal,
  records,
  setRecords,
  accounts,
  inputRef,
  settings,
  setsettings,
  setLoading
}) {
  const [Company_pic,setCompanypic]=useState(settings[0].company_pic||CompanyPic);
  const [Company_pic_send,setCompany_pic_send]=useState();
  const [Company_info,setCompany_info]=useState({
    name:'',
    email:'',
    phone:'',
    address:'',
    description:'',
  });
  
  const [account, setAccount] = useState({
    profile_picture: Profile,
    national_id_picture: IdNational,
  });



  useEffect(() => {
    const fetchAndSetImage = async (url, setImage) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = url.substring(url.lastIndexOf("/") + 1);
        const file = new File([blob], filename, {
          lastModified: new Date().getTime(),
          type: blob.type,
        });
        setImage(file);
      } catch (error) {
        console.error("Error converting URL to File:", error);
      }
    };
    if (
      settings[0].Company_pic &&
      typeof settings[0].CompanyPic === "string"
    ) {
      fetchAndSetImage(settings[0].CompanyPic, setCompany_pic_send);
    }
  }, [settings.CompanyPic]);


  const [profile_picture, setProfilePicture] = useState(null);
  const [national_id_number, setNationalIdNumber] = useState("");
  const [national_id_picture, setNationalIdPicture] = useState(null);
  const [addresss, setAddresss] = useState("");
  const [whatsup_number, setWhatsupNumber] = useState("");
  const [name, setName] = useState("");
  const [father_name, setFatherName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
        setCompany_pic_send(selectedImage);
        setCompanypic(reader.result);


        
    // ایجاد یک کپی از آرایه موجود در company_info
    const updatedCompanyInfo = [...settings];
    // به‌روزرسانی مقدار نام برای اولین آبجکت (مثلاً اندیس 0)
    updatedCompanyInfo[0] = {
      ...updatedCompanyInfo[0],
      'company_pic': reader.result,
    };
    // تنظیم وضعیت با آرایه به‌روزرسانی شده
    setsettings(updatedCompanyInfo);
        
    };
    reader.readAsDataURL(selectedImage);
  };

  const Edit_Account = () => {
    // company_pic=models.ImageField(blank=True)
    // compnay_name=models.CharField(blank=True,max_length=30)
    // description=models.TextField(blank=True)
    // address=models.TextField(max_length=50,blank=True)
    // phone=models.TextField(max_length=20,blank=True)
    // email=models.EmailField(max_length=254,blank=True)
    const uploadData = new FormData();
    if(Company_pic_send){
      uploadData.append('company_pic',Company_pic_send);
    }
    uploadData.append('compnay_name',settings[0].name);
    uploadData.append('description',settings[0].description);
    uploadData.append('address',settings[0].address);
    uploadData.append('phone',settings[0].phone);
    uploadData.append('email',settings[0].email);

    fetch(Source.getAddress() + `/api/settings/${settings[0].id}/`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
        // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
      },
      method: 'PUT',
      body: uploadData
    }).then((res) => {

      // setsettings(Settings);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `${name} successfully uploaded !`,
        showConfirmButton: false,
        timer: 1500,
      });
      let arr = [];
      // arr.push(update);
      // Setsettings(arr);
    }).catch((err) => {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "something went wrong !",
        showConfirmButton: false,
        timer: 1500,
      });
    })
  };

  const buttonRef = useRef(null);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (buttonRef.current && addAccountModal) {
        buttonRef.current.click();
      }
    }
  };

  const handle = (e) => {
    // ایجاد یک کپی از آرایه موجود در company_info
    const updatedCompanyInfo = [...settings];
    // به‌روزرسانی مقدار نام برای اولین آبجکت (مثلاً اندیس 0)
    updatedCompanyInfo[0] = {
      ...updatedCompanyInfo[0],
      [e.target.name]: e.target.value,
    };
    // تنظیم وضعیت با آرایه به‌روزرسانی شده
    setsettings(updatedCompanyInfo);
  };
  return (
    <div
      className={`container rounded-5 popup setting ${addAccountModal ? "show" : ""
        }`}
      style={{
        maxWidth: "70%",
        overflowX: "auto",
        overflowY: "auto",
        height: "10vh",
        backgroundColor: "#f8f9fa",
        padding: "20px",
      }}
    >
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn-close p-3 m-1 mt-0 hover_btn"
          onClick={close}
          aria-label="Close"
        ></button>
      </div>
      <div className="p-1 rounded-5">
        <div className="row">
          <h1
            className="text-center rounded p-4 text-light"
            style={{ backgroundColor: "var(--bs-info)", width: "100%" }}
          >
            Change Organisation Info
          </h1>
          <div className="col-12 col-md-5 mt-5">
            <label htmlFor="name" style={{ fontWeight: "bold" }}>
              Company Name
            </label>
            <input
              ref={inputRef}
              onKeyDown={handleKeyDown}
              onChange={handle}
              value={settings[0].compnay_name}
              type="text"
              name="compnay_name"
              placeholder="Name"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <label htmlFor="national_id_number" style={{ fontWeight: "bold" }}>
              Email
            </label>
            <input
              onKeyDown={handleKeyDown}
              onChange={handle}
              value={settings[0].email}
              type="email"
              name="email"
              placeholder="National Id Number"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <label htmlFor="phone" style={{ fontWeight: "bold" }}>
              Phone Number
            </label>
            <input
              onKeyDown={handleKeyDown}
              onChange={handle}
              value={settings[0].phone}
              type="text"
              name="phone"
              maxLength={10}
              placeholder="Phone Number"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <label htmlFor="address" style={{ fontWeight: "bold" }}>
              Address
            </label>
            <input
              onKeyDown={handleKeyDown}
              onChange={handle}
              value={settings[0].address}
              type="text"
              name="address"
              placeholder="Address"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-12 col-md-7 d-flex flex-column align-items-center mt-5">
            <div className="">
              <img
                className="td_img rounded"
                src={settings[0].company_pic||CompanyPic}
                style={{ height: "150px", width: "150px", objectFit: "cover" }}
                alt="Profile"
              />
            </div>
            <div className="" style={{ width: "100%" }}>
              <label htmlFor="company_pic" style={{ fontWeight: "bold" }}>
                Profile Picture
              </label>
              <input
                // onKeyDown={handleKeyDown}
                type="file"
                accept="image/*"
                name="company_pic"
                onChange={handleImageChange}
                className="form-control"
                style={{ width: "100%" }}
              />
              <div class="form-floating">
            <textarea
              // onKeyDown={handleKeyDown}
              name="description"
              class="form-control h-50"
              placeholder="Leave a comment here"
              id="floatingTextarea2"
              value={settings[0].description}
              onChange={handle}
            ></textarea>
            <label for="floatingTextarea2">Description</label>
          </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 m-2 mt-3 ps-2 p-0 pe-3">
        </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-2">
            <button
              type="button"
              className="text-center btn btn-danger text-light me-2"
              style={{ width: "150px" }}
              onClick={close}
            >
              Cancel
            </button>
            <button
              ref={buttonRef}
              type="button"
              className="text-center btn btn-success text-light ms-2"
              style={{ width: "150px" }}
              onClick={Edit_Account}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
