import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Source from "../Source";
import Swal from "sweetalert2";
import Profile from "../assets/icon/profile.png";
import IdNational from "../assets/icon/national_id.png";
import './edite.css';
export default function CustomersEdit({
  id,
  close,
  account,
  setAccount,
  edit,
  records,
  setRecords,
  inputRef,loading,setLoading
}) {
  const [profile_picture, setProfilePicture] = useState(null);
  const [national_id_picture, setNationalIdPicture] = useState(null);

  useEffect(() => {
    const fetchAndSetImage = async (url, setImage) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = url.substring(url.lastIndexOf("/") + 1);
        const file = new File([blob], filename, {
          lastModified: new Date().getTime(),
          type: blob.type,
        });
        setImage(file);
      } catch (error) {
        console.error("Error converting URL to File:", error);
      }
    };
    if (
      account.profile_picture &&
      typeof account.profile_picture === "string"
    ) {
      fetchAndSetImage(account.profile_picture, setProfilePicture);
    }
    if (
      account.national_id_picture &&
      typeof account.national_id_picture === "string"
    ) {
      fetchAndSetImage(account.national_id_picture, setNationalIdPicture);
    }
  }, [account.profile_picture, account.national_id_picture]);

  const uploadData = new FormData();
  const [profile_picture1, setProfilePicture1] = useState("");
  const [national_id_picture1, setNationalIdPicture1] = useState("");

  const Edit_Account = () => {
    // setLoading(true);
    let update = {
      id: account.id,
      isdelete: "False",
      user: 0,
      name: "",
      date_created: "",
      father_name: "",
      national_id_number: "",
      phone_number: "",
      whatsup_number: 0,
      addresss: "",
      profile_picture: "",
      national_id_picture: "",
      user_name: "",
    };
    update.date_created=account.date_created;
    update.user_name = account.user_name;
    if (profile_picture1) {
      uploadData.append("profile_picture", profile_picture1);
    } else if (profile_picture) {
      uploadData.append("profile_picture", profile_picture);
    }
    update.profile_picture = account.profile_picture;
    update.national_id_picture = account.national_id_picture;

    if (national_id_picture1) {
      uploadData.append("national_id_picture", national_id_picture1);
    } else if (national_id_picture) {
      uploadData.append("national_id_picture", national_id_picture);
    }
    uploadData.append("isdelete", "False");
    update.isdelete = "False";
    uploadData.append("user", account.user);
    update.user = account.user;
    uploadData.append("national_id_number", account.national_id_number);
    update.national_id_number = account.national_id_number;
    uploadData.append("addresss", account.addresss);
    update.addresss = account.addresss;
    uploadData.append("whatsup_number", account.whatsup_number);
    update.whatsup_number = account.whatsup_number;
    uploadData.append("name", account.name);
    update.name = account.name;
    uploadData.append("father_name", account.father_name);
    update.father_name = account.father_name;
    uploadData.append("phone_number", account.phone_number);
    update.phone_number = account.phone_number;

    fetch(Source.getAddress() + "/api/customers/" + `${account.id}/`, {
      method: "PUT", headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
        // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
      },
      body: uploadData,
    })
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Updated successfully !",
          showConfirmButton: false,
          timer: 1500,
        });
        setRecords(records.map((a) => (a.id === update.id ? update : a)));
        close();
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something went wrong !",
          showConfirmButton: false,
          timer: 1500,
        });
      })
  };

  const handleAll = (e) => {
    setAccount({
      ...account,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (event, setImageState, setAccountImageField) => {
    const selectedImage = event.target.files[0];
    setImageState(selectedImage);
    const reader = new FileReader();
    reader.onload = () => {
      setAccount((prevState) => ({
        ...prevState,
        [setAccountImageField]: reader.result,
      }));
    };
    reader.readAsDataURL(selectedImage);
  };

  const buttonRef = useRef(null);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // Check if the Enter key was pressed
      e.preventDefault(); // Prevent default action if needed
      if (buttonRef.current && edit) {
        buttonRef.current.click(); // Programmatically click the button
      }
    }
  };
  return (
    <div
      className={`container rounded-5 customer popup ${edit ? "show" : ""}`}
      style={{
        // maxWidth: "80%",
        overflowX: "auto",
        overflowY: "auto",
        // height: "76%",
        backgroundColor: "#f8f9fa",
        padding: "20px",
      }}
    >
      <div className="d-flex justify-content-end ">
        <button
          type="button"
          className="btn-close align-self-end p-3 m-1 mt-0 hover_btn"
          onClick={close}
          aria-label="Close"
        ></button>
      </div>
      <div
        className="h-50 rounded-5"
        style={{ transition: "all", transitionDuration: 1000 }}
      >
        <div className="row" style={{ flexWrap: "wrap", margin: 0 }}>
          <h1
            className="text-center rounded p-4 text-light"
            style={{ backgroundColor: "var(--bs-info)", width: "100%" }}
          >
            Edit Account
          </h1>
          <div
            className="col-12 col-md-6 d-flex flex-column align-items-center mt-5"
            style={{ maxWidth: "100%", padding: "0 15px" }}
          >
            <div>
              <img
                className="td_img rounded"
                src={account.profile_picture || Profile}
                style={{ height: "150px", width: "150px", objectFit: "cover" }}
                alt="Profile"
              />
            </div>
            <div
              className="col-12 mt-1 mb-4"
              style={{ maxWidth: "100%", padding: "0 15px" }}
            >
              <label htmlFor="profile_picture" style={{ fontWeight: "bold" }}>
                Profile Picture
              </label>
              <input
                type="file"
                accept="image/*"
                name="profile_picture"
                onChange={(e) =>
                  handleImageChange(e, setProfilePicture1, "profile_picture")
                }
                className="form-control"
                style={{ width: "100%" }}
              />
            </div>

            <div>
              <img
                className="td_img rounded"
                src={account.national_id_picture || IdNational}
                style={{ height: "150px", width: "150px", objectFit: "cover" }}
                alt="National ID"
              />
            </div>
            <div
              className="col-12 mt-1"
              style={{ maxWidth: "100%", padding: "0 15px" }}
            >
              <label
                htmlFor="national_id_picture"
                style={{ fontWeight: "bold" }}
              >
                National Id Picture
              </label>
              <input
                type="file"
                accept="image/*"
                name="national_id_picture"
                onChange={(e) =>
                  handleImageChange(
                    e,
                    setNationalIdPicture1,
                    "national_id_picture"
                  )
                }
                className="form-control"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div
            className="col-12 col-md-6 mt-5"
            style={{ maxWidth: "100%", padding: "0 15px" }}
          >
            <label htmlFor="name" style={{ fontWeight: "bold" }}>
              Name
            </label>
            <input
              onKeyDown={handleKeyDown}
              ref={inputRef}
              onChange={handleAll}
              value={account.name}
              type="text"
              name="name"
              placeholder="Name"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <label
              htmlFor="
father_name"
              style={{ fontWeight: "bold" }}
            >
              Father Name
            </label>
            <input
              onKeyDown={handleKeyDown}
              onChange={handleAll}
              value={account.father_name}
              type="text"
              name="father_name"
              placeholder="Father Name"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <label htmlFor="national_id_number" style={{ fontWeight: "bold" }}>
              National Id Number
            </label>
            <input
              onKeyDown={handleKeyDown}
              onChange={handleAll}
              value={account.national_id_number}
              type="text"
              name="national_id_number"
              placeholder="National Id Number"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <label htmlFor="phone_number" style={{ fontWeight: "bold" }}>
              Phone Number
            </label>
            <input
              onKeyDown={handleKeyDown}
              onChange={handleAll}
              value={account.phone_number}
              type="text"
              name="phone_number"
              maxLength={10}
              placeholder="Phone Number"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <label htmlFor="whatsup_number" style={{ fontWeight: "bold" }}>
              Whatsup Number
            </label>
            <input
              onChange={handleAll}
              onKeyDown={handleKeyDown}
              value={account.whatsup_number}
              type="text"
              name="whatsup_number"
              maxLength={10}
              placeholder="Whatsup Number"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <label htmlFor="addresss" style={{ fontWeight: "bold" }}>
              Address
            </label>
            <input
              onKeyDown={handleKeyDown}
              onChange={handleAll}
              value={account.addresss}
              type="text"
              name="addresss"
              placeholder="Address"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-12 d-flex flex-column flex-md-row justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="text-center form-control w-100 w-md-25 btn btn-outline-danger me-1 mb-2 mb-md-0"
              onClick={close}
            >
              Cancel
            </button>
            <button
              type="button"
              ref={buttonRef}
              className="text-center form-control w-100 w-md-25 btn btn-outline-success"
              onClick={Edit_Account}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
