import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import Recover from '../../assets/icon/recover.png';
import Trash from "../../assets/icon/trash.png";
import Source from "../../Source";
import Swal from "sweetalert2";
import { gregorianToJalali } from "shamsi-date-converter";

const formatNumber = (number) => {
  return number.toLocaleString(); // Formats number with thousand separators
};

export default function DraftAccount({
  user,
  settings,
  customers,
  money,
}) {
  const [records, setRecords] = useState([]);
  const [accounts,setAccounts]=useState([]);


  useEffect(() => {
    axios.get(Source.getAddress() + '/api/customers/', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 'False',
      },
    }).then((res)=>{
      setAccounts(res.data);
    }).catch((err)=>{
      console.log(err);
    });

  },[]);

  const res = useMemo(() => window.innerWidth > 768, []);
  useEffect(() => {
    axios
      .get(Source.getAddress() + "/api/belance/", {
        params: {
          delete: "True",
        },
        headers: {
          Authorization:`Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((res) => {
        setRecords(res.data);
      })
      .catch((err) => {
        console.error("Error fetching belance:", err);
      });
  }, []);

  const date = (d) => {
    const date = new Date(d);
    let formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    if (settings.length === 0) {
      let settings1 = { language: "English" };
      axios
        .post(Source.getAddress() + "/api/settings", settings1)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (settings[0].date === "Persian") {
      formattedDate = convertToHijriShamsi(
        `${formattedDate}  ${formattedHours}:${formattedMinutes} ${ampm}`
      );
    }
    return `${formattedDate}  ${formattedHours}:${formattedMinutes} ${ampm}`;
  };
  const handleFilter = (e) => {
    const newData = accounts.filter((row) => {
      if (typeof row.account_name === "string") {
        return (
          row.account_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          row.moneyType.toLowerCase().includes(e.target.value.toLowerCase())
        );
      }
      return false;
    });
    setRecords(newData);
  };

  const convertToHijriShamsi = (gregorianDate) => {
    const [jalaliYear, jalaliMonth, jalaliDay] = gregorianToJalali(
      new Date(gregorianDate)
    );
    return `${jalaliYear}/${jalaliMonth}/${jalaliDay}`;
  };

  const columnsDesktop = [
    { name: <strong>Account ID</strong>, selector: (row) => row.id },
    {
      name: <strong>Customer Name</strong>,
      selector: (row) => row.account_name,
    },
    {
      name: <strong>Date Created</strong>,
      selector: (row) => date(row.date_created),
    },
    { name: <strong>Money Type</strong>, selector: (row) => row.moneyType },
    {
      name: <strong>Amount</strong>,
      selector: (row) => formatNumber(row.belance),
    },
    { name: <strong>Added by</strong>, selector: (row) => row.user_name },{
      name: <strong style={{textAlign:'center',backgroundColor:'tranceparent',width:'100%'}}>Restore</strong>,
      selector: (row) =>  
        <button onClick={()=>{
          delete_restore(row);
         } } style={{ border: "none",backgroundColor:'transparent' ,height:'100%'}}>
          {row.cach}
          <img height={"20%"} width={"20%"} src={Recover} style={{backgroundColor:'tranceparent'}}  />
        </button>
        
    }
    ,
    {
      name: (
        <strong
          style={{
            textAlign: "center",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          Delete
        </strong>
      ),
      selector: (row) => (
        <button
          className={`${row.ontransaction ? "d-none" : ""}`}
          onClick={() => delete_report(row)}
          style={{
            border: "none",
            backgroundColor: "transparent",
            height: "100%",
          }}
        >
          <img
            height={"15%"}
            width={"15%"}
            src={Trash}
            style={{ backgroundColor: "transparent" }}
          />
        </button>
      ),
    }
  ];

  const columnsTablet = [
    {
      name: "account_name",
      selector: (row) => row.account_name,
      sortable: true,
    },
    {
      name: "Date Created",
      selector: (row) => row.date_created,
      sortable: true,
    },
    { name: "moneyType", selector: (row) => row.moneyType, sortable: true },
    { name: "belance", selector: (row) => row.belance, sortable: true },
  ];

  const [select_user, setselect_user] = useState("");

  const ChangeUser = (e) => {
    if (e.target.value !== "all users") {
      const newData = accounts.filter((row) => {
        if (typeof row.user_name === "string") {
          return row.user_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        return false;
      });
      setRecords(newData);
    } else {
      setRecords(accounts);
    }
    setselect_user(e.target.value);
  };

  const delete_report = async (row) => {
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});
const result = await swalWithBootstrapButtons.fire({
  title: "Are you sure?",
  text: "You won't be able to revert this!",
  icon: "warning",
  showCancelButton: true,
  confirmButtonText: "Yes, delete it!",
  cancelButtonText: "No, cancel!",
  reverseButtons: true,
});
if (result.isConfirmed) {
  axios
    .delete(
      Source.getAddress() + "/api/belance/" + `${row.id}/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        }, // Add the access token here
        // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
      }
    )
    .then((res) => {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Updated successfully !",
        showConfirmButton: false,
        timer: 1000,
      });
      setRecords(records.filter((a) => a.id !== row.id));
    })
    .catch((err) => {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Something went wrong !",
        showConfirmButton: false,
        timer: 1000,
      });
    });

} else if (result.dismiss === Swal.DismissReason.cancel) {
  swalWithBootstrapButtons.fire({
    title: "Cancelled",
    text: "Your imaginary file is safe :)",
    icon: "error",
  });
}};

const delete_restore = async (row) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger"
    },
    buttonsStyling: false
  });

  const result = await swalWithBootstrapButtons.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, restored it!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true
  });

  if (result.isConfirmed) {
    let belance_delete = {
      account: row.account,
      type: row.type,
      belance: row.belance,
      date_created: row.date_created,
      user: row.user,
      isdelete: "False",
    };
    row.isdelete = "True";
    axios
      .put(
        Source.getAddress() + "/api/belance/" + `${row.id}/`,
        belance_delete,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          }, // Add the access token here
          // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
        }
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Updated successfully !",
          showConfirmButton: false,
          timer: 1000,
        });
        setRecords(records.filter((a) => a.id !== row.id));
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something went wrong !",
          showConfirmButton: false,
          timer: 1000,
        });
      });
  
  } else if (result.dismiss === Swal.DismissReason.cancel) {
    swalWithBootstrapButtons.fire({
      title: "Cancelled",
      text: "Your imaginary file is safe :)",
      icon: "error"
    });
  }
};


  return (
    <div
      className="w-100 h-100"
    >
      <div className="container mt-5 p-0">
        <div
          className="bg-light d-flex justify-content-lg-between"
          style={{ borderTop: "5px solid #4a5cf2" }}
        >
          <h2 className="m-2 fw-bold mb-4">Accounts</h2>
          <div className="d-flex w-100 h-100 m-auto justify-content-between">
            <select
              id=""
              name="gender"
              onChange={ChangeUser}
              className="form-select h-50 m-auto"
              style={{ width: "20%" }}
              value={select_user}
            >
              <option value={"all users"}>All Users</option>
              {user.map((item) => (
                <option key={item.name} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <input
            className="form-control m-2 mb-4"
            style={{ width: "20%" }}
            onChange={handleFilter}
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </div>

        <DataTable
          columns={res ? columnsDesktop : columnsTablet}
          data={records}
          striped
          responsive
          highlightOnHover
          pagination
        />
      </div>
    </div>
  );
}
