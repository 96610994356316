import React, { useEffect, useState,useCallback } from 'react';
import Select from 'react-select';

const ComboBox = ({ options,Onsearch,selectedOption,setSelectedOption}) => {
  const handleChange = (selectedOption) => {
      setSelectedOption(selectedOption);
      Onsearch(selectedOption);
};
  return (
    <div className='w-40'>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        getOptionLabel={(option) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{`${option.account_name} ${option.moneyType}`}</span>
          </div>)}
        getOptionValue={(option) => `${option.account_name} ${option.moneyType}`}
        placeholder="Search..."
        isClearable
        isSearchable
      />
    </div>
  );
};

export default ComboBox;
