import React, { useState } from "react";
import axios from "axios";
import Trash from "../assets/icon/trash.png";
import pencil from "../assets/icon/pencil.png";
import Edit from "./Edit";
import Add from "./Add";
import "./manage.css";
export default function Manage({ users, deleteItem, setUsers }) {
  const updateItem = (id, newName) => {
    const updatedItems = users.map((item) =>
      item.id === id ? { ...item, newName } : item
    );
    setUsers(updatedItems);
  };
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [user, setUser] = useState({});
  let counter = 0;
  return (
    <div
      className="container mt-5 h-100 m-auto"
      onClick={(e) => {
        if (e.target.className === "container mt-5 h-100 m-auto") {
          setAdd(false);
          setEdit(false);
        }
      }}
    >
      <button
        onClick={() => setAdd(true)}
        type="button"
        className="btn btn-info fw-bold text-white mb-3"
      >
        Add New User
      </button>
      <div className="card card-body rounded-2 m-auto">
        <h1
          className="text-center rounded mb-4 p-4 text-light"
          style={{ backgroundColor: "var(--bs-info)" }}
        >
          Table Of Users
        </h1>

        <table className="table table-light table-striped rounded-4">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
              <th scope="col">Email</th>
              <th scope="col">Delete</th>
              <th scope="col">Edit</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {users.map((row, idx) => {
              counter++;

              return (
                <tr key={counter}>
                  <th scope="row">{row.id}</th>
                  <td>{row.username}</td>
                  <td>{row.is_staff==='True' ? 'Admin' : "Simpale"}</td>
                  <td>{row.email}</td>
                  <td className="text-center" style={{ width: "2vw" }}>
                    <button
                      onClick={() => deleteItem(row.id)}
                      className="p-0 m-0"
                      style={{ border: "none", background: "transparent" }}
                    >
                      <img
                        height={"24px"}
                        width={"24px"}
                        src={Trash}
                        alt="Delete"
                      />
                    </button>
                  </td>
                  <td className="text-center" style={{ width: "2vw" }}>
                    <button
                      onClick={() => {
                        setEdit(true);
                        setUser(row);
                      }}
                      className="p-0 m-0"
                      style={{ border: "none", background: "transparent" }}
                    >
                      <img
                        height={"24px"}
                        width={"24px"}
                        src={pencil}
                        alt="Edit"
                      />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {
          <Edit
            updateItem={updateItem}
            setUser={setUser}
            users={users}
            setUsers={setUsers}
            edit={edit}
            user={user}
            closeEdit={() => {
              setEdit(false);
              setUser({});
            }}
          />
        }
        {
          <Add
            closeAdd={() => {
              setAdd(false);
            }}
            add={add}
            users={users}
            setUsers={setUsers}
          />
        }
      </div>
    </div>
  );
}
