import React, { useState, useEffect } from "react";
import axios from "axios";
import App1 from './App1';
import Login from "./Login";
import "./assets/bootstrap-5.3.2-dist/css/bootstrap.min.css";
import bootjs from "./assets/bootstrap-5.3.2-dist/js/bootstrap.min.js";
import './login.css';
import Source from "./Source.js";

export default function App() {
    const [correct, setCorrect] = useState(false);
    const [error, setError] = useState(null);
    const [loguser, setLoguser] = useState({});
    const [type, setType] = useState('simple');

    useEffect(() => {
        const userToken = localStorage.getItem('userToken');
        if (userToken) {
            setType(userToken);
            setCorrect(true);
        }
    }, []); // Empty dependency array ensures the effect runs only once on component mount

    const check = async (user) => {
        try {
            const res = await axios.post(Source.getAddress()+'/api/token/', user);
            localStorage.setItem('access', res.data.access);
            localStorage.setItem('refresh', res.data.refresh);
            // console.log(res.data.access);
            const response = await axios.get(Source.getAddress()+'/api/current_user/', {
                headers: {
                    'Authorization': `Bearer ${res.data.access}`
                }
            });
            localStorage.setItem('userTokenname',String(response.data.is_staff)==='true' ? 'admin' : 'simple');
            localStorage.setItem('userTokenid',response.data.id);
            localStorage.setItem('userToken',response.data.username);
            localStorage.setItem('isTransactionsExpanded', false);
            localStorage.setItem('toggle', false);
            
            
            setCorrect(true);
            window.location.pathname = '/customers';
        } catch (err) {
            setCorrect(false);
            setError('Invalid username or password');
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
        }
    };

    let Component;
    if (localStorage.getItem('access')||correct) {
        Component = <App1 type={type} setLoguser={setLoguser} loguser={loguser} setCorrect={setCorrect} setError={setError} />;
    } else {
        switch (window.location.pathname) {
            case '/':
                Component = <Login check={check} error={error} />;
                break;
            default:
                Component = <Login check={check} error={error} />;
        }
    }
    return (
        <div className="App">
            {Component}
            {/* <script src={bootjs}></script> */}
        </div>
    );
}
