import React, { useState } from "react";
// import logo from './LOGO.jpg'
// import Logo from './LOGO.jpg'
import { Line } from "react-chartjs-2";

// import "./bootstrap-5.3.2-dist/css/bootstrap.min.css";
// import PrettyChart from "../PrettyChart.js";

import {
  Chart as ChartJs,
  Tooltip,
  Title,
  Legend,
  ArcElement,
  CategoryScale,
  LineElement,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJs.register(
  Tooltip,
  Title,
  ArcElement,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

export default function Dashboard() {
  const [data, setData] = useState({
    datasets: [
      {
        data: [10, 20, 30, 50, 59, 10],
        backgroundColor: [
          "#59D5E0",
          "#F5DD61",
          "#bdd6ef",
          "#F4538A",
          "#9195F6",
          "#78A083",
        ],
      },
    ],
    labels: [
      "Periodontincs",
      "Prosthodontics",
      "Oral Surgery",
      "Operative Dentistry",
      "Dental Implants",
      "Orthodontics",
    ],
  });

  const [data1, setData1] = useState({
    labels: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "First Dataset",
        data: [10, 20, 30, 42, 51, 82, 31, 59, 61, 73, 91, 58],
        backgroundColor: "#FF6868",
        borderColor: "green",
        tension: 0.4,
        fill: true,
        pointStyle: "rect",
        pointBorderColor: "blue",
        pointBackgroundColor: "#fff",
        showLine: true,
      },
      {
        label: "First Dataset",
        data: [10, 20, 30, 0, 51, 82, 31, 59, 61, 73, 91, 58],
        backgroundColor: "transparent",
        borderColor: "gree",
        tension: 0.4,
        fill: true,
        pointStyle: "rect",
        pointBorderColor: "blue",
        pointBackgroundColor: "#fff",
        showLine: true,
      }
    ],
  });

  return (
    <div class=" row m-1  pt-0 mt-0 m-5 h-100 justify-content-end">
      <div class="col bg-light h-25 w-100 m-2 p-0 rounded-4">
        <h5
          class="fs-4 h-25 w-100 px-xxl-5 p-3 m-0 rounded text-light text-center"  
          style={{ backgroundColor: "var(--bs-dark)" }}

        >
          Total Thretment
        </h5>
        <p class="m-auto p-5 fs-4 text-center">10000</p>
      </div>
      <div class="bg-light col p-0 h-25 w-100 m-2 rounded-4">
        <h5
                   class="fs-4 h-25 w-100 px-xxl-5 p-3 m-0 rounded text-light text-center"  

          style={{ backgroundColor: "var(--bs-dark)" }}
        >
          Total In thretment
        </h5>
        <p class="m-auto p-5 fs-4 text-center">10000</p>
      </div>
      <div class="bg-light col p-0 h-25 w-100 m-2 rounded-4">
        <h5
                  class="fs-4 h-25 w-100 px-xxl-5 p-3 m-0 rounded text-light text-center"  

          style={{ backgroundColor: "var(--bs-dark)" }}
        >
          Total Total Amount
        </h5>
        <p class="m-auto p-5 fs-4 text-center">10000</p>
      </div>

      <div class="h-75 row p-0 m-0">
        <div class="bg-light col-4 h-100 p-0 rounded-4 ms-2">
          <h5
                    class="fs-4 h-25 w-100 px-xxl-5 p-3 m-0 rounded text-light text-center"  

            style={{ backgroundColor: "var(--bs-dark)" }}
          >
            Total Thretment
          </h5>
          <div className="App mx-auto" style={{ width: "90%", height: "90%" }}>
            <Doughnut data={data} />
          </div>
        </div>
        <div class="bg-light col mx-0 h-100 rounded-4 me-2 p-0 w-50">
          <h5
                      class="fs-4 h-25 w-100 px-xxl-5 p-3 m-0 rounded text-light text-center"  

            style={{ backgroundColor: "var(--bs-dark)"}}
          >
            Total Thretment
          </h5>
          <div className="App" style={{ width: "100%", height: "75%" }}>
            <Line data={data1}></Line>
          </div>
          {/* <canvas id="myChart"></canvas> */}
          {/* <script src={PrettyChart}></script> */}
        </div>
      </div>
    </div>
  );
}
