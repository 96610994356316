import React, { useState, useEffect, useMemo } from "react";
import Datepicker from "../forms/Datepicker";
import DataTable from "react-data-table-component";
import axios from "axios";
import AccountEdit from "../CustomersEdit";
// import Add from '../assets/icon/add.png';
import Belance from "../forms/Belance";
import AddAccount from "../AddAccount";
import { gregorianToJalali } from "shamsi-date-converter";
import Source from "../../Source";
import Swal from "sweetalert2";
import Recover from "../../assets/icon/recover.png";
import Trash from "../../assets/icon/trash.png";
import moment from "moment-jalaali";

import { useRef } from "react";
const formatNumber = (number) => {
  return number.toLocaleString(); // Formats number with thousand separators
};

export default function DraftTransaction({
  user,
  settings,
}) {
  const [Cash,setCash]=useState([]);
  const [belance,setbelance]=useState([]);
  const [withdraw,setwithdraw]=useState([]);

  let cash = [];
  // cash = Cash;
  let name = [];
  
  useEffect(()=>{
    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 30);
    endDate.setDate(endDate.getDate() + 1);
  axios.get(Source.getAddress() + '/api/report/', {
    headers:{
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
    params: {
      delete:'False',
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(), 
    },
  }).then((res)=>{
    setRecords(res.data);
    setwithdraw(res.data);
  }).catch((res)=>{
    console.log(res);
  });
  });
  useEffect(()=>{
    axios.get(Source.getAddress() + '/api/belance/', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 'False',
      },
    }).then((res)=>{
      setbelance(res.data);
      name = res.data;
    }).catch((err)=>{
      console.log(err);
    });
   },[]); 
   useEffect(()=>{
    axios.get(Source.getAddress() + '/api/money/', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 'False',
      },
    }).then((res)=>{
      setCash(res.data);
      cash=res.data;
    }).catch((err)=>{
      console.log(err);
    });
  },[]);
  
  
  // name = belance;
  const [moneytype, setmoneytype] = useState(0);
  const [money1, setmoney] = useState({ name: "", cach: 0 });
  const [records, setRecords] = useState([]);
  const [openBelance, setOpenBelance] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editAccount, setEditAccount] = useState(null);
  const [id, setId] = useState("");
  const [idbelance, setidbelance] = useState(0);
  const [value, setValue] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [Popup, setPopup] = useState(false);
  const [addAccountModal, setAddAccountModal] = useState(false);
  const [RotateResotore, setRotateResotore] = useState(false);
  const delete_restore = async (row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, restored it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      try {
        let amount_delete = parseInt(row.amount);

        // Fetch the balance of the account
        let belacne_delete_primary_response = await axios.get(
          Source.getAddress() + `/api/belance/${row.account}/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`, // Add the access token here
              // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
            },
          }
        );
        const belacne_delete_primary = belacne_delete_primary_response.data;
        let belance_amount = parseInt(belacne_delete_primary.belance);
        // Fetch the money associated with the balance
        let money_delete_primary_response = await axios
          .get(
            Source.getAddress() +
              `/api/money/${belacne_delete_primary.moneyId}/`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access")}`, // Add the access token here
                // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
              },
            }
          )
          .catch((err) => {
            console.log("not working");
          });
        const money_delete_primary = money_delete_primary_response.data;

        let datetime = new Date();
        const jalaliDate12 = {
          year: datetime.getFullYear(),
          month: datetime.getMonth() + 1,
          day: datetime.getDay(),
        };
        const { year, month, day } = jalaliDate12;
        const t = new Date();
        const date = new Date();
        const isoString = date.toISOString();

        let cash_primary = parseInt(money_delete_primary.cach);
        let money_delete = {
          name: money_delete_primary.name,
          cach: 0,
          existense: "False",
          user: money_delete_primary.user,
        };
        let belance_delete = {
          account: belacne_delete_primary.account,
          type: belacne_delete_primary.type,
          belance: 0,
          date_created: belacne_delete_primary.date_created,
          user: belacne_delete_primary.user,
          isdelete: "False",
        };
        let update = {
          account: row.account,
          date: isoString,
          amount: row.amount,
          discription: row.discription,
          type: row.type,
          cash: row.cash,
          isdelete: "False",
          user: row.user,
        };
        if (row.type === "deposite") {
          belance_delete.belance = belance_amount + amount_delete;
          money_delete.cach = cash_primary + amount_delete;
          update.cash = cash_primary + amount_delete;
        } else {
          belance_delete.belance = belance_amount - amount_delete;
          money_delete.cach = cash_primary - amount_delete;
          update.cash = cash_primary - amount_delete;
        }
        await axios.put(
          Source.getAddress() + `/api/belance/${belacne_delete_primary.id}/`,
          belance_delete,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`, // Add the access token here
              // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
            },
          }
        );

        // Update the money
        await axios.put(
          Source.getAddress() + `/api/money/${money_delete_primary.id}/`,
          money_delete,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`, // Add the access token here
              // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
            },
          }
        );

        update.user = localStorage.getItem("userTokenid");
        await axios.put(
          Source.getAddress() + `/api/report/${row.id}/`,
          update,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`, // Add the access token here
              // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
            },
          }
        );
        const newData = records.filter((record) => record.id !== row.id);
        setRecords(newData);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Record successfully restored !",
          showConfirmButton: false,
          timer: 1000,
        });
      } catch (err) {
        // console.log('Error:', err);
        swalWithBootstrapButtons.fire({
          title: "Error",
          text: "There was an error restoring your file.",
          icon: "error",
        });
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalWithBootstrapButtons.fire({
        title: "Cancelled",
        text: "Your imaginary file is safe :)",
        icon: "error",
      });
    }
  };
  const onChange = (row) => {
    setPopup(true);
    setValue(row.target.value);
  };
  const [selectedBelance, setselectedBelance] = useState({
    account: 0,
    type: 0,
    belance: 0,
    date_created: "",
  });

  const filterDeposite = () => {
    // console.log(StartDate);
    const startDate = new Date(
      `${StartDate.year}-${StartDate.month}-${StartDate.day}`
    ); // Start date
    const endDate = new Date(`${EndDate.year}-${EndDate.month}-${EndDate.day}`); // End date
    endDate.setDate(endDate.getDate() + 2);
    const type = "withdraw";
    axios
      .get(Source.getAddress() + "/api/report/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`, // Add the access token here
          // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
        },
        params: {
          startDate: startDate,
          endDate: endDate,
          delete: "True",
        },
      })
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Deposite Table filterd successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        setRecords(res.data); // This will contain filtered data from the server
        // console.log(res.data);
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something went wrong !",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };
  const handleDeposite = (e) => {
    setAddDeposite({
      ...AddDeposite,
      [e.target.name]: e.target.value,
    });
  };
  const Onsearch = (row) => {
    setmoneytype(row.moneyId);
    setidbelance(row.id);
    setselectedBelance((prevState) => ({
      ...prevState,
      account: row.account,
      type: row.type,
      belance: row.belance, // Assuming 'belance' is correct, it seems like a typo
      date_created: row.date_created,
    }));
    let belanceName = row.account_name + " " + row.moneyType;
    setAddDeposite({
      ...AddDeposite,
      account: row.id,
    });
    setPopup(false);
    // console.log(belanceName)
    setValue(belanceName);
    // console.log('s  earch ',row);
  };

  const [AddDeposite, setAddDeposite] = useState({
    description: "",
    amount: 0,
    date: null,
    account: 0,
    type: "withdraw",
    cash: 0,
  });
  const delete_report = async (row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      try {
        const accessToken = localStorage.getItem("access");
        const response = await axios.delete(
          `${Source.getAddress()}/api/report/${row.id}/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setRecords((prevRecords) =>
          prevRecords.filter((record) => record.id !== row.id)
        );
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Record successfully Deleted",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        try {
          const refreshToken = localStorage.getItem("refresh");
          const refreshResponse = await axios.post(
            `${Source.getAddress()}api/token/refresh/`,
            {
              refresh: refreshToken,
            }
          );

          // Save the new access token
          localStorage.setItem("access", refreshResponse.data.access);
          // Retry the delete request with the new access token
          const retryResponse = await axios.delete(
            `${Source.getAddress()}api/report/${row.id}/`,
            {
              headers: {
                Authorization: `Bearer ${refreshResponse.data.access}`,
              },
            }
          );
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Record successfully deleted !",
            showConfirmButton: false,
            timer: 1000,
          });
          // console.log('Report deleted successfully after refreshing token:', retryResponse.data);
        } catch (refreshError) {
          // console.error('Failed to refresh token:', refreshError);
          swalWithBootstrapButtons.fire({
            title: "Error",
            text: "There was an error deleting your file.",
            icon: "error",
          });
        }
        // } else {
        //   // console.error('Error deleting report:', error);
        // }
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalWithBootstrapButtons.fire({
        title: "Cancelled",
        text: "Your imaginary file is safe :)",
        icon: "error",
      });
    }
  };

  const primary = useMemo(() => {
    // const record= Cash.filter((item)=>{item.id==moneytype});
    if (selectedBelance.belance === "0") {
      return -parseInt(AddDeposite.amount);
    } else {
      let primar = parseInt(selectedBelance.belance);
      primar -= parseInt(AddDeposite.amount);
      return primar;
    }
  });

  const updatemoney = useMemo(() => {
    const record = cash.find((item) => item.id === moneytype);
    if (record) {
      let money = parseInt(AddDeposite.amount);
      let primmoney = parseInt(record.cach);
      let sum = primmoney - money;
      setAddDeposite({
        ...AddDeposite,
        cash: sum,
      });
      return { name: record.name, cash: sum };
    } else {
      // Handle the case when record is undefined
      return { name: "", cash: 0 };
    }
  }, [Cash, moneytype, AddDeposite.amount]);

  // const Now_date=new Date();
  // console.log(now_date);
  const [addreport, setaddreport] = useState({
    discription: "",
    amount: 0,
    date: "",
    // type: '',
    account: 0,
    // money:'',
  });

  const handlereport = (e) => {
    setaddreport({
      ...addreport,
      [e.target.name]: e.target.value,
    });
  };

  // const [res,setres]=useState(false);
  // console.log(withdraw)
  const res = useMemo((result) => {
    if (window.innerWidth > 768) {
      return true;
    } else {
      return false;
    }
  });
  const convertToKabulTime = (dateStr) => {
    const dateObj = new Date(dateStr);
    dateObj.setMinutes(dateObj.getMinutes() + 540); // Kabul is UTC+4:30
    const formattedDateStr = dateObj.toISOString().slice(0, 16);
    return formattedDateStr;
  };
  useEffect(() => {
    let datetime = new Date();
    const jalaliDate12 = {
      year: datetime.getFullYear(),
      month: datetime.getMonth() + 1,
      day: datetime.getDay(),
    };
    const { year, month, day } = jalaliDate12;
    const t = new Date();
    const date = new Date();
    const isoString = date.toISOString();
    const fetchAccounts = async () => {
      const token = localStorage.getItem("access");
      if (!token) return;
      const fetchWithToken = async (token) => {
        const startDate = new Date();
        const endDate = new Date();
        startDate.setDate(startDate.getDate() - 10);
        endDate.setDate(endDate.getDate() + 10);
        try {
          const response = await axios.get(
            Source.getAddress() + "/api/report/",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                delete: "True",
              },
            }
          );
          setRecords(response.data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            const refreshToken = localStorage.getItem("refresh");
            if (!refreshToken) return;

            try {
              const refreshResponse = await axios.post(
                Source.getAddress() + "/api/token/refresh/",
                {
                  refresh: refreshToken,
                }
              );
              const newAccessToken = refreshResponse.data.access;
              localStorage.setItem("access", newAccessToken);
              await fetchWithToken(newAccessToken);
            } catch (refreshError) {
              console.error("Error refreshing access token:", refreshError);
            }
          } else {
            console.error("Error fetching accounts:", error);
          }
        }
      };

      await fetchWithToken(token);
    };
    fetchAccounts();
    // setRecords();
    let news = new Date();
    let mmm = news.toISOString().slice(0, 16);
    // let to = convertToKabulTime(mmm);
    setAddDeposite({
      ...AddDeposite,
      date: isoString,
      user: localStorage.getItem("userTokenid"),
    });
    setEndDate(
      `${news.getFullYear()}-${String(news.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(news.getDate()).padStart(2, "0")}`
    );
    news.setDate(news.getDate() - 1);
    setStartDate(
      `${news.getFullYear()}-${String(news.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(news.getDate()).padStart(2, "0")}`
    );
  }, [withdraw]);

  const handleRowClick = (row) => {
    setEditAccount(row);
    setEdit(true);
  };
  const convertToHijriShamsi = (gregorianDate) => {
    const [jalaliYear, jalaliMonth, jalaliDay] = gregorianToJalali(
      new Date(gregorianDate)
    );

    const hijriShamsiDate = `${jalaliYear}/${jalaliMonth}/${jalaliDay}`;

    return hijriShamsiDate;
  };
  const date = (d) => {
    const date = new Date(d);

    let formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    let aa = ampm;
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    if (localStorage.getItem("date") === "Persian") {
      moment.locale("fa"); // تنظیم لوکال به فارسی
      aa = hours >= 12 ? "ب.ظ" : "ق.ظ";
      formattedDate = moment(d).format("jYYYY-jMM-jDD");
    } else {
      moment.locale("en"); // تنظیم لوکال به انگلیسی
    }

    return `${formattedDate} ${formattedHours}:${formattedMinutes} ${aa}`;
  };

  const handleViewBelance = (row) => {
    const filteredBelance = belance.filter((item) => item.account === row.id);

    setEditAccount(filteredBelance);
    setId(row.id);
    setOpenBelance(true);
  };
  const handleFilter = (e) => {
    // console.log(accounts);
    const newData = withdraw.filter((row) => {
      if (typeof row.customer === "string") {
        if (
          row.customer.toLowerCase().includes(e.target.value.toLowerCase()) ||
          row.moneyType.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          return row.customer;
        }
      }
      return false;
    });
    setRecords(newData);
  };

  const ShowDeposit = (type) => {
    return (
      <p
        className={`text-capitalize position-static p-2 mb-0 ${
          type === "deposite" ? "bg-success" : "bg-danger"
        } rounded text-light text-center `}
        style={{ width: "75px" }}
      >
        {type}
      </p>
    );
  };

  const columnsDesktop = [
    {
      name: <strong>ID</strong>,
      selector: (row) => row.id,
      //  sortable: true
    },

    {
      name: <strong>Customer Name</strong>,
      selector: (row) => row.customer,
      style: {
        padding: "0px 20px",
        justifyContent: "left",
        textAlign: "center",
      },
      // sortable: true
    },
    {
      name: <strong>Transaction</strong>,
      selector: (row) => ShowDeposit(row.type),
    },
    {
      name: (
        <strong
          style={{ minWidth: "170px", maxWidth: "200px", width: "200px" }}
        >
          Date Created
        </strong>
      ),
      selector: (row) => date(row.date),
      style: {
        minWidth: "170px", // Adjust this value as needed
        maxWidth: "200px", // Adjust this value as needed
      },
      // sortable: true,
    },
    // { name: <strong>Time Created</strong>, selector: (row) => time(row.date_created),
    // //  sortable: true
    // },
    {
      name: <strong>Money Type</strong>,
      selector: (row) => row.moneyType,
      //  sortable: true
    },
    {
      name: <strong>Amount</strong>,
      selector: (row) => formatNumber(row.amount),
      //  sortable: true
    },
    {
      name: <strong>Description</strong>,
      selector: (row) => row.discription,
    },
    // {
    //   name: <strong>Cash Belance</strong>,
    //   selector: (row) => formatNumber(row.cash),
    // },
    {
      name: <strong>Deleted by </strong>,
      selector: (row) => row.user_name,
    },
    {
      name: (
        <strong
          style={{
            textAlign: "center",
            backgroundColor: "tranceparent",
            width: "100%",
          }}
        >
          Restore
        </strong>
      ),
      selector: (row) => (
        <button
          onClick={() => {
            delete_restore(row);
          }}
          style={{
            border: "none",
            backgroundColor: "transparent",
            height: "100%",
          }}
        >
          {row.cach}
          <img
            height={"30%"}
            width={"30%"}
            src={Recover}
            style={{ backgroundColor: "tranceparent" }}
          />
        </button>
      ),
    },
    {
      name: (
        <strong
          style={{
            textAlign: "center",
            backgroundColor: "tranceparent",
            width: "100%",
          }}
        >
          Delete
        </strong>
      ),
      selector: (row) => (
        <button
          onClick={() => {
            delete_report(row);
          }}
          style={{
            border: "none",
            backgroundColor: "transparent",
            height: "100%",
          }}
        >
          <img
            height={"22%"}
            width={"22%"}
            src={Trash}
            style={{ backgroundColor: "tranceparent" }}
          />
        </button>
      ),
    },
  ];

  const columnsTablet = [
    // { name: "Id", selector: (row) => row.id, sortable: true },

    { name: "account_name", selector: (row) => row.customer, sortable: true },
    {
      name: "Date Created",
      selector: (row) => row.date_created,
      sortable: true,
    },
    { name: "moneyType", selector: (row) => row.moneyType, sortable: true },
    { name: "belance", selector: (row) => row.belance, sortable: true },
  ];
  const [select_user, setselect_user] = useState("");
  const ChangeUser = (e) => {
    if (e.target.value !== "all users") {
      const newData = withdraw.filter((row) => {
        if (typeof row.user_name === "string") {
          return row.user_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        return false;
      });
      setRecords(newData);
      console.log(e.target.value);
    } else {
      setRecords(withdraw);
    }
    setselect_user(e.target.value);
  };

  const filterRef = useRef(null);

  const Hanlderef = (e) => {
    if (e.key === "Enter") {
      // Check if the Enter key was pressed
      e.preventDefault(); // Prevent default action if needed
      if (filterRef.current) {
        filterRef.current.click(); // Programmatically click the button
      }
    }
  };

  return (
    <div className="container mt-5">
      <div
        className="bg-light d-flex flex-column flex-lg-row justify-content-lg-between align-items-start align-items-lg-center"
        style={{ borderTop: "5px solid #4a5cf2" }}
      >
        <h2 className="m-2 fw-bold mb-4">Draft Transactions</h2>
        <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
          <div className="mb-2 mb-lg-0 me-lg-2">
            <Datepicker
              default_value={StartDate}
              settings={settings}
              onKeyDown={Hanlderef}
              handle_date={(e) => setStartDate(e)}
              lebal={"Start"}
            />
          </div>
          <div className="mb-2 mb-lg-0 me-lg-2">
            <Datepicker
              onKeyDown={Hanlderef}
              default_value={EndDate}
              settings={settings}
              handle_date={(e) => setEndDate(e)}
              lebal={"End"}
            />
          </div>
          <button
            ref={filterRef}
            onClick={() => {
              filterDeposite();
            }}
            type="button"
            className="btn btn-success mb-2 mb-lg-0 w-100 w-lg-50 h-50"
            style={{ marginTop: "2rem" }}
          >
            Filter
          </button>
          <select
            id=""
            name="gender"
            className="form-select mb-2 mb-lg-0 ms-lg-5 mt-4"
            style={{ width: "100%", maxWidth: "200px" }}
            value={select_user}
            onChange={ChangeUser}
          >
            <option value={"all users"} style={{ height: "100px" }} selected>
              All Users
            </option>
            {user.map((item) => (
              <option value={item.name} key={item.name}>
                <p> {item.name}</p>
              </option>
            ))}
          </select>
        </div>
        <input
          className="form-control m-2 mb-2 mt-4"
          style={{ width: "100%", maxWidth: "200px" }}
          type="search"
          onChange={handleFilter}
          placeholder="Search"
          aria-label="Search"
        />
      </div>
      <DataTable
        columns={res ? columnsDesktop : columnsTablet}
        data={records}
        onRowClicked={handleRowClick}
        striped
        responsive
        highlightOnHover
        pagination
      />
      {edit && (
        <AccountEdit
          close={() => setEdit(false)}
          account={editAccount}
          setAccount={setEditAccount}
        />
      )}
    {openBelance && (
        <Belance
          close={() => setOpenBelance(false)}
          id={id}
          accountbelance={editAccount}
        />
      )}
      {addAccountModal && (
        <AddAccount close={() => setAddAccountModal(false)} />
      )}
    </div>
  );
}
