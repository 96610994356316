import axios from "axios";
import { updateLocale } from "moment-timezone";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Source from "../Source";
import ComboBox from "./forms/ComboBox";
import Reset from "./settings/Reset";
import Money from "./Money";
import CompanyPic from '../assets/icon/company.png'
import AddInfo from "./forms/AddInfo";
// import {ComboBoxComponent} from '@syncfusion/ej2-react-dropdowns'
export default function Setting({ date, setdate, username, settings, setsettings, money, belance, setMoney, user }) {
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupReset,setShowPopupReset] = useState(false);
  const [showmoney, setshowmoney] = useState(false);
  const [CompanyPicture, SetCompanyPicture] = useState(CompanyPic)
  const [AddinfoModel,setAddinfoModel]=useState(false);
  const [Settings, Setsettings] = useState([{
    id: settings[0].id,
    date: '',
    language: '',
  }]);
  useEffect(() => {
    Setsettings(settings)
  }, [settings])
  const handle = (e) => {
    let update = {
      date: '',
      language: '',
    }
    Setsettings({
      ...Settings,
      [e.target.name]: e.target.value,
    });
    localStorage.setItem(e.target.name, e.target.value);
    const uploadData = new FormData();
    let name = '';
    name = e.target.name;
    name = name.toUpperCase();
    if (e.target.name === 'date') {
      update.date = e.target.value;
      update.language = settings[0].language;
      uploadData.append(e.target.name, e.target.value);
      uploadData.append('language', settings[0].language);
    } else {
      update.date = settings[0].date;
      update.language = e.target.value;
      uploadData.append(e.target.name, e.target.value);
      uploadData.append('date', settings[0].date);
    }
    fetch(Source.getAddress() + `/api/settings/${settings[0].id}/`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
        // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
      },
      method: 'PUT',
      body: uploadData
    }).then((res) => {
      setsettings(Settings);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `${name} successfully uploaded !`,
        showConfirmButton: false,
        timer: 1500,
      });
      let arr = [];
      arr.push(update);
      Setsettings(arr);
    }).catch((err) => {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "something went wrong !",
        showConfirmButton: false,
        timer: 1500,
      });
    })
    // uploadData.append();
  };
  // console.log(username);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const [Companypic, setCompanypic] = useState();
  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (event.target.name === "companypic") {
        setCompanypic(selectedImage);
        SetCompanyPicture(reader.result);
      }
    };
    reader.readAsDataURL(selectedImage);
  };

  return (
    <div className="popup-container mx-auto"
      style={{ width: '40%' }}
    >
      <div className="container bg-light mt-5"
        style={{ borderTop: "5px solid #4a5cf2" }}

      >
        <table class="table table-light table-striped"
        >
          <thead>
            <tr>
              <h2 className="m-2 fw-bold mb-4 w-100">Settings</h2>
              {/* <th scope="col"></th> */}
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr>
              <td className="d-flex justify-content-between"
                style={{ backgroundColor: 'black !important' }}
              >
                <strong className="fs-5 p-1 me-5">Date</strong>
                <select
                  id=""
                  name="date"
                  onChange={handle}
                  class="form-select h-50 ms-2"
                  style={{ width: "30%" }}
                  value={Settings[0].date}
                >
                  <option value={'Persian'} className="p-2" selected>
                    Persian
                  </option>
                  {/* {user.map((item) =>( */}
                  <option value={'English'} className="p-2" selected>English</option>
                  {/* ))} */}
                </select>
              </td>
            </tr>
            <tr>
              <td className="d-flex justify-content-between"
                style={{ backgroundColor: 'black !important' }}
              >
                <strong className="fs-5 p-1 me-5">Language</strong>
                <select
                  id=""
                  name="language"
                  onChange={handle}
                  class="form-select h-50 ms-2"
                  style={{ width: "30%" }}
                  value={Settings[0].language}
                >
                  <option value={"Persian"} className="m-4" selected>
                    Persian
                  </option>
                  <option value={'English'} className="m-2" selected>English</option>
                </select>
              </td>
            </tr>
            <tr>
              <td className="d-flex justify-content-between"
                style={{ backgroundColor: 'black !important' }}
              >
                <strong className="fs-5 p-1 me-5">Reset To Defualt</strong>
                <button
                    onClick={() => {
                      // setAddinfoModel(true);
                      setShowPopupReset(true);
                      
                    }}
                    type="button"
                    className="btn btn-outline-danger fw-bold m-2 me-3"
                  >
                    Reset
                  </button>
              </td>
            </tr>
            <tr>  
              <td className=" justify-content-between"
                style={{ backgroundColor: 'black !important' }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <strong className="fs-5 p-1">Organisation Info</strong>
                  <button
                    onClick={() => {
                      setAddinfoModel(true);
                      
                    }}
                    type="button"
                    className="btn btn-outline-success fw-bold m-2 me-3"
                  >
                    Change
                  </button>
                </div>
                <div className="ps-2 d-flex justify-center">
                  <div className="mt-3 align-content-center" style={{ width: "70%" }}>
                    <div className="m-2">
                      <pre className="fw-bold">Company Name: {settings[0].compnay_name} </pre>
                      <pre className="fw-bold">Address: {settings[0].address} </pre>
                      <pre className="fw-bold">Phone: {settings[0].phone}</pre>
                      <pre className="fw-bold">Email: {settings[0].email}</pre>
                      <p>Description: {settings[0].description}</p>
                    </div>
                  </div>

                  <div style={{ width: "150px", height: "150px" }}>
                    <img
                      className="td_img rounded"
                      src={settings[0].company_pic}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", // Ensures the image covers the container area
                        outline: '2px solid aliceblue'
                      }}
                      alt="Profile"
                    />
                  </div>
                </div>
              </td>
            </tr>
            {/* {source.length>=6 && <a o className="text-primary underlined text-center">{text}</a>} */}
          </tbody>
        </table>
      </div>
      <Reset 
        addAccountModal={showPopupReset}
        close={() => setShowPopupReset(false)}
      />
      <AddInfo
          // loading={loading}
          // setLoading={setLoading}
            // inputRef={inputRef}
            settings={settings}
            setsettings={setsettings}
            close={() => setAddinfoModel(false)}
            addAccountModal={AddinfoModel}
                      
          />
    </div>
  );
}