import React, { useState, useEffect, useMemo,useRef } from "react";
import DataTable from "react-data-table-component";
import { gregorianToJalali } from "shamsi-date-converter";
// import { gregorianToJalali } from "shamsi-date-converter";
import Swal from "sweetalert2";
import Trash from "../assets/icon/trash.png";
import axios from "axios";
import AccountEdit from "./CustomersEdit";
import Add from "../assets/icon/add.png";
import Belance from "./forms/Belance";
import AddAccount from "./AddAccount";
import Source from "../Source";
import Profile from "../assets/icon/profile.png";
import IdNational from "../assets/icon/national_id.png";

export default function Customers({ date1 ,settings, user,setsettings,loading,setLoading,}) {
  // let user1=user;
  const [money,setMoney]=useState([]);
  const [belance, setbelance]=useState([]);
  const [records, setRecords] = useState();

  // const [belance, setBelance] = useState([]);
  const [ accounts, setAccounts]=useState([]);
  const [openBelance, setOpenBelance] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editAccount, setEditAccount] = useState(null);
  const [editAccount1, setEditAccount1] = useState({
    id: 0,
    isdelete: "False",
    user: 0,
    name: "",
    date_created: "",
    father_name: "",
    national_id_number: "",
    phone_number: "",
    whatsup_number: 0,
    addresss: "",
    profile_picture: "",
    national_id_picture: "",
  });
  const [id, setId] = useState("");
  const [addAccountModal, setAddAccountModal] = useState(false);
  // const [res,setres]=useState(false);
  const res = useMemo((result) => {
    if (window.innerWidth > 768) {
      return true;
    } else {
      return false;
    }
  });
useEffect(()=>{
  axios.get(Source.getAddress() + '/api/money/', {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('access')}`,
    },params: {
      delete: 'False',
    },
  }).then((res)=>{
    setMoney(res.data);
  }).catch((err)=>{
    console.log(err);
  });
},[]);
  useEffect(() => {
    axios.get(Source.getAddress() + '/api/customers/', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 'False',
      },
    }).then((res)=>{
      setAccounts(res.data);
      setRecords(res.data);
    }).catch((err)=>{
      console.log(err);
    });

  },[]);
 useEffect(()=>{
  axios.get(Source.getAddress() + '/api/belance/', {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('access')}`,
    },params: {
      delete: 'False',
    },
  }).then((res)=>{
    setbelance(res.data);
  }).catch((err)=>{
    console.log(err);
  });
 },[]); 
  const handleRowClick = (row) => {
    setOpenBelance(false);
    setEditAccount1(row);
    setEdit(true);
    inputRef1.current.select();
    setAddAccountModal(false)
  };
  const [customer,setcustomer]=useState({
    national_id_picture:'',
      profile_picture:'',
      national_id_number:'',
      isdelete:'False',
      user:'',
      address:'',
      whatsup_number:'',
      name:'',
      father_name:'',
      phone_number:'',
      // ontransaction:'True'
  });
  const [prof,setprof]=useState();
  const [idprof,setidprof]=useState();
  const handleViewBelance = (row) => {
    inputRefbelance.current.select();       
    const fetchAndSetImage = async (url, setImage) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = url.substring(url.lastIndexOf("/") + 1);
        const file = new File([blob], filename, {
          lastModified: new Date().getTime(),
          type: blob.type,
        });
        setImage(file);
      } catch (error) {
        console.error("Error converting URL to File:", error);
      }
    };
    if(row.profile_picture){
      fetchAndSetImage(row.profile_picture, setprof);
    }else{
      setprof('');
    }
    if(row.national_id_picture){
      fetchAndSetImage(row.national_id_picture, setidprof);
    }else{
      setidprof('');
    }
    setcustomer(row);
    setEdit(false);
    const filteredBelance = belance.filter((item) => item.account === row.id);
    setEditAccount(filteredBelance);
    setId(row.id);
    setOpenBelance(true);
    setAddAccountModal(false)
  };

  // const [records, setRecords] = useState(rows);

  const convertToHijriShamsi = (gregorianDate) => {
    // Convert Gregorian date to Hijri Shamsi
    // const gregorianDate = "2000-11-11";
    const [jalaliYear, jalaliMonth, jalaliDay] = gregorianToJalali(
      new Date(gregorianDate)
    );

    const hijriShamsiDate = `${jalaliYear}/${jalaliMonth}/${jalaliDay}`;

    return hijriShamsiDate;
  };

  const handleFilter = (e) => {
    const newData = accounts.filter((row) => {
      if (typeof row.name === "string") {
        return row.name.toLowerCase().includes(e.target.value.toLowerCase());
      }
      return false;
    });
    setRecords(newData);
  };
  const [ProfilePicture,setProfilePicture]=useState();
  const [national_id_picture,setNationalIdPicture]=useState();
  const delete_report= async (row)=>{
    const fetchAndSetImage = async (url, setImage) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = url.substring(url.lastIndexOf("/") + 1);
        const file = new File([blob], filename, {
          lastModified: new Date().getTime(),
          type: blob.type,
        });
        setImage(file);
      } catch (error) {
        console.error("Error converting URL to File:", error);
      }
    };
    if (
      row.profile_picture &&
      typeof row.profile_picture === "string"
    ) {
      fetchAndSetImage(row.profile_picture, setProfilePicture);
    }
    if (
      row.national_id_picture &&
      typeof row.national_id_picture === "string"
    ) {
      fetchAndSetImage(row.national_id_picture, setNationalIdPicture);
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });
    if (result.isConfirmed) {
const uploadData = new FormData();
if(ProfilePicture){
  uploadData.append("profile_picture", ProfilePicture);
}
if(national_id_picture){
  uploadData.append("national_id_picture", national_id_picture);
}
uploadData.append("isdelete", "True");
uploadData.append("ontransaction", "False");
uploadData.append("user", row.user);
// update.user = localStorage.getItem("userTokenid");
uploadData.append("national_id_number", row.national_id_number);
// update.national_id_number = row.national_id_number;
uploadData.append("addresss", row.addresss);
// update.addresss = row.addresss;
uploadData.append("whatsup_number", row.whatsup_number);
// update.whatsup_number = row.whatsup_number;
uploadData.append("name", row.name);
// update.name = row.name;
uploadData.append("father_name", row.father_name);
// update.father_name = row.father_name;
uploadData.append("phone_number", row.phone_number);
// update.phone_number = row.phone_number;

fetch(Source.getAddress() + "/api/customers/" + `${row.id}/`, {
  method: "PUT", headers: {
    'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
    // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
  },
  body: uploadData,
})
  .then((res) => {
    console.log(res);
    setRecords(records.filter((a) => a.id !== row.id));
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Updated successfully !",
      showConfirmButton: false,
      timer: 1000,
    });
  })
  .catch((error) => {
    console.log(error);
    Swal.fire({
      position: "top-end",
      icon: "error",
      title: "Something went wrong !",
      showConfirmButton: false,
      timer: 1000,
    });
  })



    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalWithBootstrapButtons.fire({
        title: "Cancelled",
        text: "Your imaginary file is safe :)",
        icon: "error",
      });
    } 
  }
  const handleAddAccount = () => {
    setEdit(false)
    setAddAccountModal(true);
    setOpenBelance(false)
    inputRef.current.select();
  };

  const date = (d) => {
    const date1 = new Date(d);
    const formattedDate = `${date1.getFullYear()}-${String(
      date1.getMonth() + 1
    ).padStart(2, "0")}-${String(date1.getDate()).padStart(2, "0")}`;
    // return convertToHijriShamsi(gregorianDate);
    if(settings[0].date==='Persian'){
      const gregorianDate = new Date(formattedDate);
      return convertToHijriShamsi(gregorianDate);
    }else{
      return formattedDate;
    }
  }
    
  
  const columnsDesktop = [
    { name: "Id", selector: (row) => row.id, sortable: true 
      ,style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    {
      name: "Photo",
      cell: (row) => (
        <img
          src={row.profile_picture===null ? Profile : row.profile_picture}
          alt={row.name}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),style: {
        width: "1px",
        minWidth: "1px",
      },
      sortable: true,
    },
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Father Name", selector: (row) => row.father_name, sortable: true },
    {
      name: "Date Created",
      selector: (row) => 
        date(row.date_created),
    },
    { name: "Address", selector: (row) => row.addresss, sortable: true },
    { name: "Phone", selector: (row) => row.phone_number, sortable: true },
    { name: "Whatsup", selector: (row) => row.whatsup_number, sortable: true },
    {
      name: "National Id Number",
      selector: (row) => row.national_id_number,
      sortable: true,
    },
    {
      name: "Belance",
      cell: (row) => (
        <button
          onClick={() => handleViewBelance(row)}
          type="button"
          className="btn btn-outline-info rounded-4"
          style={{ fontSize: "0.9rem" }}
        >
          View
        </button>
      ),
    },
    {
      name: "Added by ",
      selector: (row) => row.user_name,
      sortable: true,
    },{
      name: (
        <p
          style={{
            margin:'auto auto',
            textAlign: "center",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          Delete
        </p>
      ),
      selector: (row) => (
        <button
          className={`${row.ontransaction ? "d-none" : ""}`}
          onClick={() =>
             delete_report(row)
            }
          style={{
            border: "none",
            backgroundColor: "transparent",
            height: "100%",
          }}
        >
          <img
            height={"25%"}
            width={"25%"}
            src={Trash}
            style={{ backgroundColor: "transparent" }}
          />
        </button>
      ),
    },
  ];
  const inputRefbelance = useRef(null); // Create a ref for the input field


  const columnsTablet = [
    {
      name: "Photo",
      cell: (row) => (
        <img
          src={row.profile_picture===null ? Profile : row.profile_picture}
          alt={row.name}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    { name: "Name", selector: (row) => row.name, sortable: true },
    {
      name: "Belance",
      cell: (row) => (
        <button
          onClick={() => {
            handleViewBelance(row);
          }}
          type="button"
          className="btn btn-outline-info rounded-4"
          style={{ fontSize: "0.9rem" }}
        >
          View
        </button>
      ),
    },
  ];
  const [select_user,setselect_user]=useState('');
  const ChangeUser=(e)=>{
    if(e.target.value!=='all users'){
    const newData = accounts.filter((row) => {
      if (typeof row.user_name=== "string") {
        return row.user_name.toLowerCase().includes(e.target.value.toLowerCase());
      }
      return false;
    });
    setRecords(newData);}
    else{setRecords(accounts)}
    setselect_user(e.target.value);
  }
  const inputRef=useRef(null);
  const inputRef1=useRef(null);

  return (
    <div
      className={`h-100 w-100 ${settings[0].language === "Persian" && "iransans"}`}
      onClick={(e) => {
        if (
          e.target.className == "container mt-5" ||
          e.target.className == "main" ||
          e.target.className == "h-100 w-100"
        ) {
          setOpenBelance(false);
          setEdit(false);
          setAddAccountModal(false);
        } else {
        }
      }}
    >
      <div className="mt-5 m-5" style={{ height: "100%" }}>
      <button
          onClick={handleAddAccount}
          type="submit"
          className="btn btn-info mb-1 p-1"
          style={{ width: "100px" }}
        >
          Add
        </button>
        <div className="bg-light d-flex justify-content-lg-between"
          style={{borderTop: "5px solid #4a5cf2"}}
        
        >
          <h2 className="m-2 fw-bold mb-4">Customers</h2>
          <div className="d-flex w-100 h-100 m-auto justify-content-between">
            <select
              id=""
              name="gender"
              onChange={ChangeUser}
              class="form-select h-50 m-auto"
              style={{ width: "20%" }}
              value={select_user}
            >
              <option value={'all users'} selected>All Users</option>
              {user.map((item) =>(
              <option value={item.name} selected>{item.name}</option>
              ))}
            </select>
          </div>
          <input
            className="form-control m-2 mb-4"
            style={{ width: "20%" }}
            onChange={handleFilter}
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </div>

        <DataTable
          // title="Customers"
          columns={res ? columnsDesktop : columnsTablet}
          data={records}
          onRowClicked={handleRowClick}
          striped
          responsive
          highlightOnHover
          pagination
          // noHeader
          // noTableHead
        />
        {
          <AccountEdit
          loading={loading}
          setLoading={setLoading}
          inputRef={inputRef1}
            close={() => setEdit(false)}
            account={editAccount1}
            setAccount={setEditAccount1}
            // records={records}
            // setRecords={setRecords}
            edit={edit}
            records={records}
            setRecords={setRecords}
          />
        }
        <Belance
        setMoney={setMoney}
        inputRef={inputRefbelance}
        belancem={belance}
         setbelance={setbelance}
        prof={prof}
        idprof={idprof}
        customer={customer}
        setcustomer={setcustomer}
        moneyp={money}
        loading={loading}
        setLoading={setLoading}
          close={() => setOpenBelance(false)}
          id={id}
          accountbelance={editAccount}
          setEditAccount={setEditAccount}
          openBelance={openBelance}
          setOpenBelance={setOpenBelance}
        />
        {
          <AddAccount
          loading={loading}
          setLoading={setLoading}
            inputRef={inputRef}
            close={() => setAddAccountModal(false)}
            addAccountModal={addAccountModal}
            records={records}
            setRecords={setRecords}
          />
        }
      </div>
    </div>
  );
}