import React, { useState, useEffect, useMemo, useRef } from "react";
import DataTable from "react-data-table-component";
import { gregorianToJalali } from "shamsi-date-converter";
// import { gregorianToJalali } from "shamsi-date-converter";
import Swal from "sweetalert2";
import Trash from "../../assets/icon/trash.png";
import Recover from "../../assets/icon/recover.png";
import axios, { Axios } from "axios";

import Source from "../../Source.js";
import Profile from "../../assets/icon/profile.png";
export default function DraftCustomers({
  date1,
  user,
  settings,
  setsettings,
  loading,
  setLoading,
  belance,
  setbelance,
  money,
}) {
  const [accounts,setAccounts]=useState([]);
  useEffect(() => {
    axios.get(Source.getAddress() + '/api/customers/', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 'False',
      },
    }).then((res)=>{
      setAccounts(res.data);
      setRecords(res.data);
    }).catch((err)=>{
      console.log(err);
    });

  },[]);
  // let user1=user;
  const [records, setRecords] = useState();
  // const [belance, setBelance] = useState([]);
  const [openBelance, setOpenBelance] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editAccount, setEditAccount] = useState(null);
  const [editAccount1, setEditAccount1] = useState({
    id: 0,
    isdelete: "False",
    user: 0,
    name: "",
    date_created: "",
    father_name: "",
    national_id_number: "",
    phone_number: "",
    whatsup_number: 0,
    addresss: "",
    profile_picture: "",
    national_id_picture: "",
  });
  const [id, setId] = useState("");
  const [addAccountModal, setAddAccountModal] = useState(false);
  // const [res,setres]=useState(false);
  const res = useMemo((result) => {
    if (window.innerWidth > 768) {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    const fetchAccounts = async () => {
      const token = localStorage.getItem("access");
      if (!token) return;
      const fetchWithToken = async (token) => {
        try {
          const response = await axios.get(
            Source.getAddress() + "/api/customers/",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                delete: "True",
              },
            }
          );
          setRecords(response.data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            const refreshToken = localStorage.getItem("refresh");
            if (!refreshToken) return;

            try {
              const refreshResponse = await axios.post(
                Source.getAddress() + "/api/token/refresh/",
                {
                  refresh: refreshToken,
                }
              );
              const newAccessToken = refreshResponse.data.access;
              localStorage.setItem("access", newAccessToken);
              await fetchWithToken(newAccessToken);
            } catch (refreshError) {
              // console.error('Error refreshing access token:', refreshError);
            }
          } else {
            //   console.error('Error fetching accounts:', error);
          }
        }
      };
      await fetchWithToken(token);
    };
    fetchAccounts();
  }, []);

  const [customer, setcustomer] = useState({
    national_id_picture: "",
    profile_picture: "",
    national_id_number: "",
    isdelete: "False",
    user: "",
    address: "",
    whatsup_number: "",
    name: "",
    father_name: "",
    phone_number: "",
  });
  const convertToHijriShamsi = (gregorianDate) => {
    const [jalaliYear, jalaliMonth, jalaliDay] = gregorianToJalali(
      new Date(gregorianDate)
    );

    const hijriShamsiDate = `${jalaliYear}/${jalaliMonth}/${jalaliDay}`;

    return hijriShamsiDate;
  };

  const handleFilter = (e) => {
    const newData = accounts.filter((row) => {
      if (typeof row.name === "string") {
        return row.name.toLowerCase().includes(e.target.value.toLowerCase());
      }
      return false;
    });
    setRecords(newData);
  };
  const delete_report = async (row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
  
    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });
  
    if (result.isConfirmed) {
      try {
        const response = await axios.delete(Source.getAddress() + "/api/customers/" + `${row.id}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        });
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Updated successfully!",
          showConfirmButton: false,
          timer: 600,
        });
  
        // Update the state to remove the deleted record
        setRecords(records.filter((a) => a.id !== row.id));
        
      } catch (err) {
        console.error('Delete error:', err);
  
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: false,
          timer: 600,
        });
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalWithBootstrapButtons.fire({
        title: "Cancelled",
        text: "Your imaginary file is safe :)",
        icon: "error",
      });
    }
  };
  
  const handleAddAccount = () => {
    setEdit(false);
    setAddAccountModal(true);
    setOpenBelance(false);
    inputRef.current.select();
  };

  const date = (d) => {
    const date1 = new Date(d);
    const formattedDate = `${date1.getFullYear()}-${String(
      date1.getMonth() + 1
    ).padStart(2, "0")}-${String(date1.getDate()).padStart(2, "0")}`;
    // return convertToHijriShamsi(gregorianDate);
    if (settings[0].date === "Persian") {
      const gregorianDate = new Date(formattedDate);
      return convertToHijriShamsi(gregorianDate);
    } else {
      return formattedDate;
    }
  };
  const [ProfilePicture,setProfilePicture]=useState();
  const [national_id_picture,setNationalIdPicture]=useState();
  const delete_restore = async (row) => {

    const fetchAndSetImage = async (url, setImage) => {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const filename = url.substring(url.lastIndexOf("/") + 1);
          const file = new File([blob], filename, {
            lastModified: new Date().getTime(),
            type: blob.type,
          });
          setImage(file);
        } catch (error) {
          console.error("Error converting URL to File:", error);
        }
      };
      if (
        row.profile_picture &&
        typeof row.profile_picture === "string"
      ) {
        fetchAndSetImage(row.profile_picture, setProfilePicture);
      }
      if (
        row.national_id_picture &&
        typeof row.national_id_picture === "string"
      ) {
        fetchAndSetImage(row.national_id_picture, setNationalIdPicture);
      }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
  
    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, restored it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    });
  
    if (result.isConfirmed) {
        const uploadData = new FormData();
        if(ProfilePicture){
          uploadData.append("profile_picture", ProfilePicture);
        }
        if(national_id_picture){
          uploadData.append("national_id_picture", national_id_picture);
        }
        uploadData.append("isdelete", "True");
        uploadData.append("ontransaction", "False");
        uploadData.append("user", row.user);
        // update.user = localStorage.getItem("userTokenid");
        uploadData.append("national_id_number", row.national_id_number);
        // update.national_id_number = row.national_id_number;
        uploadData.append("addresss", row.addresss);
        // update.addresss = row.addresss;
        uploadData.append("whatsup_number", row.whatsup_number);
        // update.whatsup_number = row.whatsup_number;
        uploadData.append("name", row.name);
        // update.name = row.name;
        uploadData.append("father_name", row.father_name);
        // update.father_name = row.father_name;
        uploadData.append("phone_number", row.phone_number);
        // update.phone_number = row.phone_number;
        
        fetch(Source.getAddress() + "/api/customers/" + `${row.id}/`, {
          method: "PUT", headers: {
            'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
            // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
          },
          body: uploadData,
        })
          .then((res) => {
            console.log(res);
            setRecords(records.filter((a) => a.id !== row.id));
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Updated successfully !",
              showConfirmButton: false,
              timer: 1000,
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Something went wrong !",
              showConfirmButton: false,
              timer: 1000,
            });
          })
        
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalWithBootstrapButtons.fire({
        title: "Cancelled",
        text: "Your imaginary file is safe :)",
        icon: "error"
      });
    }
  };

  const columnsDesktop = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    {
      name: "Photo",
      cell: (row) => (
        <img
          src={row.profile_picture === null ? Profile : row.profile_picture}
          alt={row.name}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      style: {
        width: "1px",
        minWidth: "1px",
      },
      sortable: true,
    },
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Father Name", selector: (row) => row.father_name, sortable: true },
    {
      name: "Date Created",
      selector: (row) => date(row.date_created),
    },
    { name: "Address", selector: (row) => row.addresss, sortable: true },
    { name: "Phone", selector: (row) => row.phone_number, sortable: true },
    { name: "Whatsup", selector: (row) => row.whatsup_number, sortable: true },
    {
      name: "National Id Number",
      selector: (row) => row.national_id_number,
      sortable: true,
    },
    {
      name: "Added by ",
      selector: (row) => row.user_name,
      sortable: true,
    },{
        name: <strong style={{textAlign:'center',backgroundColor:'tranceparent',width:'100%'}}>Restore</strong>,
        selector: (row) =>  
          <button onClick={()=>{
            delete_restore(row);
           } } style={{ border: "none",backgroundColor:'transparent' ,height:'100%'}}>
            {row.cach}
            <img height={"30%"} width={"30%"} src={Recover} style={{backgroundColor:'tranceparent'}}  />
          </button>
          
      },
    {
      name: (
        <p
          style={{
            margin: "auto auto",
            textAlign: "center",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          Delete
        </p>
      ),
      selector: (row) => (
        <button
          onClick={() => delete_report(row)}
          style={{
            border: "none",
            backgroundColor: "transparent",
            height: "100%",
          }}
        >
          <img
            height={"25%"}
            width={"25%"}
            src={Trash}
            style={{ backgroundColor: "transparent" }}
          />
        </button>
      ),
    },
  ];
  const columnsTablet = [
    {
      name: "Photo",
      cell: (row) => (
        <img
          src={row.profile_picture === null ? Profile : row.profile_picture}
          alt={row.name}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    { name: "Name", selector: (row) => row.name, sortable: true },
  ];
  const [select_user, setselect_user] = useState("");
  const ChangeUser = (e) => {
    if (e.target.value !== "all users") {
      const newData = accounts.filter((row) => {
        if (typeof row.user_name === "string") {
          return row.user_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        return false;
      });
      setRecords(newData);
    } else {
      setRecords(accounts);
    }
    setselect_user(e.target.value);
  };
  const inputRef = useRef(null);
  return (
    <div
      className={`h-100 w-100 ${
        settings[0].language === "Persian" && "iransans"
      }`}
      onClick={(e) => {
        if (
          e.target.className == "container mt-5" ||
          e.target.className == "main" ||
          e.target.className == "h-100 w-100"
        ) {
          setOpenBelance(false);
          setEdit(false);
          setAddAccountModal(false);
        } else {
        }
      }}
    >
      <div className="mt-5 m-5" style={{ height: "100%" }}>
        <div
          className="bg-light d-flex justify-content-lg-between"
          style={{ borderTop: "5px solid #4a5cf2" }}
        >
          <h2 className="m-2 fw-bold mb-4">Customers</h2>
          <div className="d-flex w-100 h-100 m-auto justify-content-between">
            <select
              id=""
              name="gender"
              onChange={ChangeUser}
              class="form-select h-50 m-auto"
              style={{ width: "20%" }}
              value={select_user}
            >
              <option value={"all users"} selected>
                All Users
              </option>
              {user.map((item) => (
                <option value={item.name} selected>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <input
            className="form-control m-2 mb-4"
            style={{ width: "20%" }}
            onChange={handleFilter}
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </div>
        <DataTable
          columns={res ? columnsDesktop : columnsTablet}
          data={records}
          striped
          responsive
          highlightOnHover
          pagination
        />
      </div>
    </div>
  );
}
