import React, { useState } from "react";
import axios from "axios";
import Source from "../Source";
import Swal from "sweetalert2";

export default function Edit({users,setUsers, closeEdit, edit, setUser, user, updateItem }) {
  const [error, setError] = useState(false);

  const createUser = (user) => {
    const update = {
      username: user.username,
      password: password,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      is_staff: user.is_staff,
    };
    if (user.username && user.password && user.email) {
      axios
        .put(`${Source.getAddress()}/api/users/${user.id}/`, update, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "User has been updated!",
            showConfirmButton: false,
            timer: 1000,
          });
          console.log(user);
          setUsers(users.map((row)=>(row.id === user.id ? user :row)));
          closeEdit();
        })
        .catch((error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Something went wrong!",
            showConfirmButton: false,
            timer: 1000,
          });
        });
    } else {
      setError(true);
      Swal.fire({
        title: "Error",
        text: "You must fill in all required fields!",
        icon: "error",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUser({
      ...user,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const [password,setpassword]=useState();
  const handlepassword=(e)=>{
    setpassword(e.target.value);
  }
  return (
    <div className={`container rounded-4 p-2  popup ${edit ? "show" : ""}`} style={{ maxWidth: '400px' }}>
      <h4 className="text-center bg-info mt-4 p-3 text-light w-100">
        Edit User
      </h4>
      <div className="mb-3 mt-5">
        <label htmlFor="username" className="form-label">
          User Name
        </label>
        <input
          type="text"
          name="username"
          onChange={handleChange}
          value={user.username}
          className="form-control"
          id="username"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="password" className="form-label">
          New Password
        </label>
        <input
          type="password"
          name="password"
          onChange={handlepassword}
          value={password}
          className="form-control"
          id="password"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="is_staff" className="form-label">
          Type
        </label>
        <select
          id="is_staff"
          name="is_staff"
          onChange={handleChange}
          className="form-select"
          value={user.is_staff}
        >
          <option value={"True"}>Admin</option>
          <option value={"False"}>Simple</option>
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="last_name" className="form-label">
          Last Name
        </label>
        <input
          type="text"
          name="last_name"
          onChange={handleChange}
          value={user.last_name}
          className="form-control"
          id="last_name"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          name="email"
          onChange={handleChange}
          value={user.email}
          className="form-control"
          id="email"
        />
      </div>

      {error && (
        <div className="text-light ps-2 opacity-75 rounded bg-danger font-weight-bold mt-0">
          You must fill in all required fields.
        </div>
      )}
      <div className="mt-1 justify-content-around d-flex">
        <button
          type="button"
          className="col-5 m-2 mt-3 btn btn-outline-danger fw-bold"
          onClick={() => {
            closeEdit();
            setError(false);
          }}
        >
          Cancel
        </button>
        <button
          onClick={() => {
            createUser(user);
          }}
          type="button"
          className="col-5 m-2 mt-3 btn btn-outline-success fw-bold"
        >
          Save
        </button>
      </div>
    </div>
  );
}
