import React, { useState, useEffect, useMemo } from "react";
import Source from "./Source";
// import Sidebar from "./Sidebar.js";
import { Collapse } from "react-bootstrap";
import { useCollapse } from "react-collapsed";
// import useCollapse from "./usecollapse.js";
import Setting_pic from "./assets/icon/settings.png";
import Customer_pic from "./assets/icon/customers_pic.png";
import Deposite_pic from "./assets/icon/deposite.png";
import Withdraw_pic from "./assets/icon/withdraw.png";
import CustomerReport from "./assets/icon/CustomerReport.png";
import DraftCustomers from "./assets/icon/DraftCustomer.png";
import All_Transactions from "./assets/icon/all_transaction.png";
import DraftAccount from "./assets/icon/DraftAccount.png";
import DraftTransaction from "./assets/icon/DraftTransaction.png";
import DraftMoney from "./assets/icon/DraftMoney.png";
import Cash_pic from "./assets/icon/money.png";
import ItemsPic from "./assets/icon/items.png";
import ItemPic from "./assets/icon/item.png";
import StockPic from "./assets/icon/stock.png";
import MoneyType from "./assets/icon/moneytype.png";
import PurchasePic from "./assets/icon/purchase.png";
import SellPic from "./assets/icon/sell.png";
import Down from "./assets/icon/down.png";
import Menu from "./assets/icon/menuu.png";
import Business from "./assets/icon/Business.png";
import Logout from "./assets/icon/logout.png";
import Manager from "./assets/icon/manager.png";
import MoneyPic from "./assets/icon/money.png";
import DashboardPic from "./assets/icon/dashboard.png";
import Wellcom from "./pages/forms/Wellcom";
import AccountPic from "./assets/icon/accounts.png";
import Customers from "./pages/Customers.js";
import Draft_pic from "./assets/icon/draft.png";
import ItemTypePic from "./assets/icon/itemtype.png";
import Change from "./assets/icon/changepass.png";
import expand from "./assets/icon/down.png";
import alldraft from "./assets/icon/alldraft.png";
import ThirdDeptDtable from "./pages/ThirdDeptDtable.js";
import "../src/App.css";
import Loading from "./Loading.js";

export default function Sidebar(isToggled) {
  const [isReportCollapsed, setIsReportCollapsed] = useState(
    JSON.parse(localStorage.getItem("ReportCollapseState"))
  );
  const [isTransactionCollapsed, setIsTransactionCollapsed] = useState(
    JSON.parse(localStorage.getItem("transactionCollapseState"))
  );
  const [isDraftExpanded, setIsDraftExpanded] = useState(
    JSON.parse(localStorage.getItem("draftCollapseState"))
  );

  const [isStockExpanded, setIsStockExpanded] = useState(
    JSON.parse(localStorage.getItem("StockCollapseState"))
  );

  const handleReportToggle = () => {
    setIsReportCollapsed((prev) => !prev);
    localStorage.setItem("ReportCollapseState", !isReportCollapsed);
  };
  const handleTransactionToggle = () => {
    setIsTransactionCollapsed((prev) => !prev);
    localStorage.setItem("transactionCollapseState", !isTransactionCollapsed);
  };
  const handleDraftToggle = () => {
    setIsDraftExpanded((prev) => !prev);
    localStorage.setItem("draftCollapseState", !isDraftExpanded);
  };

  const handleStockToggle = () => {
    setIsStockExpanded((prev) => !prev);
    localStorage.setItem("StockCollapseState", !isStockExpanded);
  };

  const ReportToggleOpen = () => {
    setIsTransactionCollapsed((prev) => false);
    localStorage.setItem("transactionCollapseState", false);
  };
    const TransactionToggleOpen = () => {
    setIsTransactionCollapsed((prev) => false);
    localStorage.setItem("transactionCollapseState", false);
  };
  const DraftToggleOpen = () => {
    setIsDraftExpanded((prev) => false);
    localStorage.setItem("draftCollapseState", false);
  };

  const StockToggleOpen = () => {
    setIsStockExpanded((prev) => false);
    localStorage.setItem("StockCollapseState", false);
  };

  const Close = () => {
    setIsDraftExpanded((prev) => true);
    localStorage.setItem("draftCollapseState", true);
    setIsTransactionCollapsed((prev) => true);
    localStorage.setItem("transactionCollapseState", true);
    setIsStockExpanded((prev) => true);
    localStorage.setItem("StockCollapseState", true);
    
    setIsReportCollapsed((prev) => true);
    localStorage.setItem("ReportCollapseState", true);
  };
  const CloseTransaction = () => {
    setIsTransactionCollapsed((prev) => true);
    localStorage.setItem("transactionCollapseState", true);
  };
  const CloseReport = () => {
    setIsReportCollapsed((prev) => true);
    localStorage.setItem("ReportCollapseState", true);
  };
  const CloseDraft = () => {
    setIsDraftExpanded((prev) => true);
    localStorage.setItem("draftCollapseState", true);
  };

  const CloseStock = () => {
    setIsStockExpanded((prev) => true);
    localStorage.setItem("StockCollapseState", true);
  };

  return (
    <aside
      id={isToggled.isToggled ? "sidebar" : "collapsed"}
      style={{ backgroundColor: "#4a5cf2", width: "15%" }}
      className="fw-bold "
    >
      <div className="h-100" style={{ backgroundColor: "white" }}>
        <div
          className="sidebar-logo m-0"
          style={{ backgroundColor: "#4a5cf2", height: "3.3rem" }}
        >
          <a href="#">Gold Database</a>
        </div>
        <ul className="sidebar-nav pt-0">
          <li
            className="sidebar-header mt-5 fw-bold fs-5"
            style={{ color: "black" }}
          >
            Navbar
          </li>
          <li className="sidebar-item">
            <a
              onClick={() => {
                handleTransactionToggle();
                CloseDraft();
                CloseStock();
                CloseReport();
              }}
              className={`sidebar-link ${
                localStorage.getItem("transactionCollapseState") === "false" &&
                "backgroundblue text-light"
              }`}
              style={{ color: "black" }}
            >
              <img src={Business} alt="" className="navhome" />
              Transactions
              <img
                src={expand}
                alt=""
                className={`navhome ${
                  isTransactionCollapsed ? "not-rotated" : "rotated"
                }`}
                style={{ marginLeft: "4.8rem", width: "7%", height: "7%" }}
              />
            </a>
            <Collapse in={!isTransactionCollapsed}>
              <ul className="list-unstyled ms-4">
              <li>
                  <a
                    href="/alltransactions"
                    className={`sidebar-link ${
                      window.location.pathname === "/alltransactions" &&
                      "bluebackground text-light"
                    }`}
                    style={{ color: "black",marginLeft: "0.2rem" }}
                    onClick={() => {
                      localStorage.setItem("transactionExpand", false);
                      localStorage.setItem("collapseTransaction", true);
                      TransactionToggleOpen();
                      CloseDraft();
                      CloseReport();
                    }}
                  >
                    <img src={All_Transactions} alt="" className="navhome"style={{width:'10%',height:'10%'}} />
                    All Transactions
                  </a>
                </li>
                <li>
                  <a
                    href="/deposite"
                    className={`sidebar-link ${
                      window.location.pathname === "/deposite" &&
                      "bluebackground text-light"
                    }`}
                    style={{ color: "black",marginLeft: "0.2rem" }}
                    onClick={() => {
                      localStorage.setItem("transactionExpand", false);
                      localStorage.setItem("collapseTransaction", true);
                      TransactionToggleOpen();
                      CloseDraft();
                    }}
                  >
                    <img src={Deposite_pic} alt="" className="navhome"style={{width:'10%',height:'10%'}} />
                    Deposite
                  </a>
                </li>
                <li>
                  <a
                    href="/withdraw"
                    className={`sidebar-link ${
                      window.location.pathname === "/withdraw" &&
                      "bluebackground text-light"
                    }`}
                    style={{ color: "black" }}
                    onClick={() => {
                      TransactionToggleOpen();
                      CloseDraft();
                    }}
                  >
                    <img src={Withdraw_pic} alt="" className="navhome" style={{width:'13%',height:'13%'}}/>
                    Withdraw
                  </a>
                </li>
              </ul>
            </Collapse>
          </li>
          <li className="sidebar-item">
            <a
              onClick={() => {
                handleReportToggle();
                CloseDraft();
                CloseStock();
                CloseTransaction();
              }}
              className={`sidebar-link ${
                localStorage.getItem("ReportCollapseState") === "false" &&
                "backgroundblue text-light"
              }`}
              style={{ color: "black" }}
            >
              <img src={CustomerReport} alt="" className="navhome" />
              Reports
              <img
                src={expand}
                alt=""
                className={`navhome ${
                  isReportCollapsed ? "not-rotated" : "rotated"
                }`}
                style={{ marginLeft: "7rem", width: "7%", height: "7%" }}
              />
            </a>
            <Collapse in={!isReportCollapsed}>
              <ul className="list-unstyled ms-4">
                <li>
                  <a
                    href="/customerreport"
                    className={`sidebar-link ${
                      window.location.pathname === "/customerreport" &&
                      "bluebackground text-light"
                    }`}
                    style={{ color: "black",marginLeft: "0.2rem" }}
                    onClick={() => {
                      localStorage.setItem("ReportExpand", false);
                      localStorage.setItem("collapseReport", true);
                      CloseReport();
                      CloseDraft();
                      Close();
                    }}
                  >
                    <img src={Deposite_pic} alt="" className="navhome"style={{width:'10%',height:'10%'}} />
                    Customer Reports
                  </a>
                </li>
                {/* <li>
                  <a
                    href="/withdraw"
                    className={`sidebar-link ${
                      window.location.pathname === "/withdraw" &&
                      "bluebackground text-light"
                    }`}
                    style={{ color: "black" }}
                    onClick={() => {
                      ReportToggleOpen();
                      CloseDraft();
                    }}
                  >
                    <img src={Withdraw_pic} alt="" className="navhome" style={{width:'13%',height:'13%'}}/>
                    Withdraw
                  </a>
                </li> */}
              </ul>
            </Collapse>
          </li>
          <li className="sidebar-item">
            <a
              href="/customers"
              className={`sidebar-link ${
                window.location.pathname === "/customers" &&
                "backgroundblue text-light"
              }`}
              style={{ color: "black" }}
              onClick={Close}
            >
              <img src={Customer_pic} alt="" className="navhome" />
              Customers
            </a>
          </li>
          <li className="sidebar-item">
            <a
              href="/cash"
              className={`sidebar-link ${
                window.location.pathname === "/cash" &&
                "backgroundblue text-light"
              }`}
              style={{ color: "black" }}
              onClick={Close}
            >
              <img src={Cash_pic} alt="" className="navhome" />
              Cash
            </a>
          </li>
          <li className="sidebar-item">
            <a
              href="/account"
              className={`sidebar-link ${
                window.location.pathname === "/account" &&
                "backgroundblue text-light"
              }`}
              style={{ color: "black" }}
              onClick={Close}
            >
              <img src={AccountPic} alt="" className="navhome" />
              Accounts
            </a>
          </li>
          <li className="sidebar-item">
            <a
              href="/money_type"
              className={`sidebar-link ${
                window.location.pathname === "/money_type" &&
                "backgroundblue text-light"
              }`}
              style={{ color: "black" ,marginLeft:'0rem'}}
              onClick={Close}
            >
              <img src={MoneyType} alt="" className="navhome" style={{width:'13%',height:'13%'}} />
              Money Type
            </a>
          </li>
          {/* <li className="sidebar-item">
            <a
              onClick={() => {
                handleStockToggle();
                CloseDraft();
                CloseTransaction();
                CloseReport();
              }}
              className={`sidebar-link ${
                localStorage.getItem("StockCollapseState") === "false" &&
                "backgroundblue text-light"
              }`}
              style={{ color: "black" }}
            >
              <img src={ItemsPic} alt="" className="navhome" style={{width:'13%',height:'13%'}}/>
              Items
              <img
                src={expand}
                alt=""
                className={`navhome ${
                  isStockExpanded ? "not-rotated" : "rotated"
                }`}
                style={{ marginLeft: "7.8rem", width: "7%", height: "7%" }}
              />
            </a>
            <Collapse in={!isStockExpanded}>
              <ul className="list-unstyled ms-4">
                <li>
                  <a
                    href="/itemtype"
                    className={`sidebar-link ${
                      window.location.pathname === "/itemtype" &&
                      "bluebackground text-light"
                    }`}
                    style={{ color: "black" }}
                    onClick={() => {
                      localStorage.setItem("StockExpand", false);
                      localStorage.setItem("collapseStock", true);
                      StockToggleOpen();
                      // CloseStock();
                    }}
                  >
                    <img src={ItemTypePic} alt="" className="navhome" />
                    Item Type
                  </a>
                </li>
                <li>
                  <a
                    href="/items"
                    className={`sidebar-link ${
                      window.location.pathname === "/items" &&
                      "bluebackground text-light"
                    }`}
                    style={{ color: "black" }}
                    onClick={() => {
                      StockToggleOpen();
                      // CloseStock();
                    }}
                  >
                    <img src={ItemPic} alt="" className="navhome" />
                    Items
                  </a>
                </li><li>
                  <a
                    href="/stock"
                    className={`sidebar-link ${
                      window.location.pathname === "/stock" &&
                      "bluebackground text-light"
                    }`}
                    style={{ color: "black" }}
                    onClick={() => {
                      StockToggleOpen();
                      // CloseStock();
                    }}
                  >
                    <img src={StockPic} alt="" className="navhome" />
                    Stock
                  </a>
                </li><li>
                  <a
                    href="/purchase"
                    className={`sidebar-link ${
                      window.location.pathname === "/purchase" &&
                      "bluebackground text-light"
                    }`}
                    style={{ color: "black" }}
                    onClick={() => {
                      StockToggleOpen();
                      // CloseStock();
                    }}
                  >
                    <img src={PurchasePic} alt="" className="navhome" />
                    Purchase
                  </a>
                </li><li>
                  <a
                    href="/sell"
                    className={`sidebar-link ${
                      window.location.pathname === "/sell" &&
                      "bluebackground text-light"
                    }`}
                    style={{ color: "black" }}
                    onClick={() => {
                      StockToggleOpen();
                      // CloseStock();
                    }}
                  >
                    <img src={SellPic} alt="" className="navhome" />
                    Sell
                  </a>
                </li>
              </ul>
            </Collapse>
          </li> */}
          {/*  */}

          <li className="sidebar-item">
            <a
              onClick={() => {
                handleDraftToggle();
                CloseTransaction();
                CloseStock();
                CloseReport();
              }}
              className={`sidebar-link ${
                localStorage.getItem("draftCollapseState") === "false" &&
                "backgroundblue text-light"
              }`}
              style={{ color: "black" }}
            >
              <img src={alldraft} alt="" className="navhome" />
              All Draft
              <img
                src={expand}
                alt=""
                className={`navhome ${
                  isDraftExpanded ? "not-rotated" : "rotated"
                }`}
                style={{ marginLeft: "7rem", width: "7%", height: "7%" }}
              />
            </a>
            <Collapse in={!isDraftExpanded}>
              <ul className="list-unstyled ms-4">
                <li>
                  <a
                    href="/drafttransactions"
                    className={`sidebar-link ${
                      window.location.pathname === "/drafttransactions" &&
                      "bluebackground text-light"
                    }`}
                    style={{ color: "black" }}
                    onClick={() => {
                      DraftToggleOpen();
                      CloseTransaction();
                    }}
                  >
                    <img src={DraftTransaction} alt="" className="navhome" />
                    Draft Transactions
                  </a>
                </li>
                <li>
                  <a
                    href="/draftcustomer"
                    className={`sidebar-link ${
                      window.location.pathname === "/draftcustomer" &&
                      "bluebackground text-light"
                    }`}
                    style={{ color: "black" }}
                    onClick={() => {
                      DraftToggleOpen();
                      CloseTransaction();
                    }}
                  >
                    <img src={DraftCustomers} alt="" className="navhome" />
                    Draft Customers
                  </a>
                </li>
                <li>
                  <a
                    href="/draftmoney"
                    className={`sidebar-link ${
                      window.location.pathname === "/draftmoney" &&
                      "backgroundblue text-light"
                    }`}
                    style={{ color: "black" }}
                    onClick={() => {
                      DraftToggleOpen();
                      CloseTransaction();
                    }}
                  >
                    <img src={DraftMoney} alt="" className="navhome" />
                    Draft Money
                  </a>
                </li>
                {localStorage.getItem("userTokenname") === "admin" && (
                  <li>
                    <a
                      href="/draftaccount"
                      className={`sidebar-link ${
                        window.location.pathname === "/draftaccount" &&
                        "bluebackground text-light"
                      }`}
                      style={{ color: "black" }}
                      onClick={() => {
                        DraftToggleOpen();
                        CloseTransaction();
                      }}
                    >
                      <img src={DraftAccount} alt="" className="navhome" />
                      Draft Account
                    </a>
                  </li>
                )}
              </ul>
            </Collapse>
          </li>
          <li className="sidebar-item">
            <a
              href="/setting"
              className={`sidebar-link ${
                window.location.pathname === "/setting" &&
                "backgroundblue text-light"
              }`}
              style={{ color: "black" }}
              onClick={Close}
            >
              <img src={Setting_pic} alt="" className="navhome" />
              Settings
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
}
