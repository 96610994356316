import React, { useState } from 'react';
import './login.css';
export default function Login({ error, check }) {
    const [user, setUser] = useState({ username: '', password: '' });

    const handleAddUser = (e) => {
        e.preventDefault(); // Prevent form submission
        check(user);
    };
  
    return (
        <div className="container-fluid">
            <form className="mx-auto">
                <h4 className="text-center">Login</h4>
                <div className="mb-3 mt-5">
                    <label htmlFor="exampleInputEmail1" className="form-label">User Name</label>
                    <input type="text" name='username' onChange={(e) => setUser({ ...user, username: e.target.value })} value={user.username} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                    <input type="password" name='password' onChange={(e) => setUser({ ...user, password: e.target.value })} value={user.password} className="form-control" id="exampleInputPassword1"/>
                </div>
                
                {error && <div className="text-danger font-weight-bold mt-3">{error}</div>}
                <a href='/customers' type="submit" onClick={handleAddUser} className="btn btn-primary mt-5">Login</a>
            </form>
        </div>
    )
}