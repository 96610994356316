import React, { useEffect, useState, useRef } from "react";
import Source from "../Source";
import axios from "axios";
import Swal from "sweetalert2";
import Profile from "../assets/icon/profile.png";
import IdNational from "../assets/icon/national_id.png";
import Combo_Customer from "./forms/Combo_Customer";
import Datepicker from "./forms/Datepicker";
import Select from "react-select";
import jalaali from "jalaali-js";
import Datepicker_Customer from "./forms/Datepicker_customer";
import moment from "moment-jalaali";

export default function Add_belance({
  setAccounts,
  close,
  addAccountModal,
  records,
  setRecords,
  customers,
  accounts,
  money,
  settings,
}) {
  const [bbelance, setbbelance] = useState({
    account: null,
    type: null,
    belance: 0,
    date_created: "",
    user: null,
    isdelete: false,
  });
  const [selectedDay, setSelectedDay] = useState(moment());
  const [selectedOption, setSelectedOption] = useState();
  const [add, setadd] = useState({
    id: 0,
    account: 0,
    type: 0,
    belance: 0,
    date_created: "",
    moneyType: "",
    account_name: "",
    moneyId: 0,
    user: localStorage.getItem("userTokenid"),
    user_name: localStorage.getItem("userToken"),
    isdelete: false,
  });
  const [Customer, setCustomer] = useState({
    national_id_picture: "",
    profile_picture: "",
    national_id_number: "",
    isdelete: "False",
    user: "",
    address: "",
    whatsup_number: "",
    name: "",
    father_name: "",
    phone_number: "",
    // ontransaction:'True'
  });
  const [smoney, setsmeony] = useState();
  const algorithm = (e) => {
    if (e) {
      setCustomer(e);
      setbbelance({
        ...bbelance,
        account: e.id,
      });
      setadd({
        ...add,
        account_name: e.name,
        account: e.id,
      });
      // find the main account in belance
      const find = records.filter((p) => p.account === e.id);
      console.log(find);
      console.log(records);
      let moneys = [];
      for (let i = 0; i < money.length; i++) {
        const res = find.find((r) => r.type === money[i].id);
        if (!res) {
          moneys.push(money[i]);
        }
      }
      setsmeony(moneys);
    }
  };
  const [nmoney, setnmoney] = useState();
  const handle = (e) => {
    const date = new Date();
    const isoString = date.toISOString();
    setnmoney(e);
    if (e) {
      setbbelance({
        ...bbelance,
        user: localStorage.getItem("userTokenid"),
        type: e.id,
        date_created: isoString,
      });
      const newId =
        records.length > 0
          ? Math.max(...records.map((record) => record.id)) + 1
          : 1;
      setadd({
        ...add,
        user: localStorage.getItem("userTokenid"),
        type: e.id,
        id: newId,
        date_created: isoString,
        moneyType: e.name,
        user_name: localStorage.getItem("userToken"),
      });
    }
  };

  const handle_date = (jalaliDate) => {
    // console.log(jalaliDate);
    if (jalaliDate) {
      const { year, month, day } = jalaliDate;
      const gregorianDate = jalaali.toGregorian(year, month, day);
      // Get current time
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      // Create the ISO date string
      let isoDateString;
      const date = new Date(
        jalaliDate.year,
        jalaliDate.month - 1,
        jalaliDate.day,
        now.getHours(),
        now.getMinutes()
      );
      const isoString = date.toISOString(); // This gives you the ISO string in UTC

      // setIsoDate(isoString);
      console.log(isoString);
      setbbelance({
        ...bbelance,
        date_created: isoString,
      });
      setadd({ ...add, date_created: isoString });
      // const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}`;
    }
  };
  const [national_id_picture,setNationalIdPicture]=useState();
  const [ProfilePicture,setProfilePicture]=useState();
  const sumbit = () => {
    const fetchAndSetImage = async (url, setImage) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = url.substring(url.lastIndexOf("/") + 1);
        const file = new File([blob], filename, {
          lastModified: new Date().getTime(),
          type: blob.type,
        });
        setImage(file);
      } catch (error) {
        console.error("Error converting URL to File:", error);
      }
    };
    if (
      Customer.profile_picture &&
      typeof Customer.profile_picture === "string"
    ) {
      fetchAndSetImage(Customer.profile_picture, setProfilePicture);
    }
    if (
      Customer.national_id_picture &&
      typeof Customer.national_id_picture === "string"
    ) {
      fetchAndSetImage(Customer.national_id_picture, setNationalIdPicture);
    }
    axios
      .post(Source.getAddress() + "/api/belance/", bbelance, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`, // Add the access token here
          // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
        },
      })
      .then((res) => {
        console.log(res);
        setadd(prev=>[{...prev,
          id:res.data.new_account_id
        }])
        let customer = {
          national_id_number: Customer.national_id_number,
          isdelete: Customer.isdelete,
          user: Customer.user,
          address: Customer.address,
          whatsup_number: Customer.whatsup_number,
          name: Customer.name,
          father_name: Customer.father_name,
          phone_number: Customer.phone_number,
          ontransaction: "True",
        };
        if(national_id_picture){
          customer['national_id_picture'] =national_id_picture;
        }
        if(ProfilePicture){
          customer['profile_picture'] = ProfilePicture;
        }
        axios
          .put(
            Source.getAddress() + "/api/customers/" + `${Customer.id}/`,
            customer,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access")}`, // Add the access token here
                // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
              },
            }
          )
          .then((res) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Your belance has been added !",
              showConfirmButton: false,
              timer: 800,
            });
          })
          .catch((err) => {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Your belance not add correctly !",
              showConfirmButton: false,
              timer: 800,
            });
          });

        setbbelance({
          account: null,
          type: null,
          belance: 0,
          date_created: "",
          user: null,
          isdelete: false,
        });
        setSelectedOption(null);
        setnmoney(null);
        setsmeony(null);
        setSelectedDay(moment());

        // console.log(bbelance);
        setRecords([add, ...records]);
        close();
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Your belance not add !",
          showConfirmButton: false,
          timer: 800,
        });
      });
  };

  const buttonRef = useRef(null);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // Check if the Enter key was pressed
      e.preventDefault(); // Prevent default action if needed
      if (buttonRef.current && add) {
        buttonRef.current.click(); // Programmatically click the button
      }
    }
  };

  return (
    <div
      className={`rounded-4 row g-2 popup m-4 mb-0 ${
        addAccountModal && "show"
      }`}
      style={{ width: "26rem" }}
    >
      <div className="d-flex justify-content-end m-0 p-0">
        <button
          type="button"
          class="btn-close align-self-end p-3 m-1 mt-0 hover_btn"
          onClick={() => {
            close();
          }}
          aria-label="Close"
        ></button>
      </div>
      <h1
        className="text-center rounded m-0 p-2 w-100 text-light fs-4"
        style={{ backgroundColor: "var(--bs-info)" }}
      >
        Add Account
      </h1>
      <div class="col-md-6">
        <label for="validationServer01" class="">
          Customer Name
        </label>
        <Combo_Customer
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          options={customers}
          Onsearch={algorithm}
        />
        <div class="valid-feedback">Looks good!</div>
      </div>
      <div class="col-md-6">
        <label for="validationServer02" class="">
          Money Type
        </label>
        {/* <input type="text" class="form-control is-valid" id="validationServer02" value="" required /> */}
        <div className="w-40">
          <Select
            onKeyDown={handleKeyDown}
            value={nmoney}
            onChange={handle}
            options={smoney}
            getOptionLabel={(option) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <img src={option.pictureUrl} alt={`Portrait of ${option.firstName} ${option.lastName}`} style={{ width: '30px', marginRight: '10px' }} /> */}
                <span>{`${option.name} `}</span>
              </div>
            )}
            getOptionValue={(option) => `${option.name}`}
            placeholder="Search..."
            isClearable
            isSearchable
            isDisabled={smoney ? false : true}
          />
        </div>
        <div class="valid-feedback">Looks good!</div>
      </div>
      <div className="coll-10">
        <Datepicker_Customer
          onKeyDown={handleKeyDown}
          default_value={""}
          settings={settings}
          handle_date={handle_date}
          lebal={"Date"}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
        ></Datepicker_Customer>
      </div>
      <div className="col-10 ms-4 m-10 mt-5 ps-3 d-flex">
        <a
          className="text-center form-control btn btn-danger text-light me-1"
          style={{ margin: "auto", width: "150px" }}
          onClick={close}
        >
          Cancel
        </a>
        <a
          className="text-center btn form-control btn-success text-light ms-1"
          ref={buttonRef}
          style={{ margin: "auto", width: "150px" }}
          onClick={() => {
            sumbit();
          }}
        >
          Submit
        </a>
      </div>
    </div>
  );
}
