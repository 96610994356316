import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import Source from "../../Source";
import Trash from "../../assets/icon/trash.png";
import Restore from "../../assets/icon/recover.png";
import pencil from "../../assets/icon/pencil.png";
import Swal from "sweetalert2";
import "../forms/money.css";
export default function DraftMoney({
  user,
  settings,
}) {
  const [belance, setbelance]=useState([]);
  const [money,setMoney]=useState([]);
  useEffect(()=>{
    axios.get(Source.getAddress() + '/api/belance/', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 'False',
      },
    }).then((res)=>{
      setbelance(res.data);
    }).catch((err)=>{
      console.log(err);
    });
   },[]); 
  useEffect(()=>{
    axios.get(Source.getAddress() + '/api/money/', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 'False',
      },
    }).then((res)=>{
      setMoney(res.data);
    }).catch((err)=>{
      console.log(err);
    });
  },[]);
  const [add, setAdd] = useState(false);
  const [update, setUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [record, setRecords] = useState([]);
  const Delete = async (row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      axios
        .delete(Source.getAddress() + "/api/money/" + `${row.id}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((res) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Money deleted successfully !",
            showConfirmButton: false,
            timer: 600,
          });
        })
        .catch((err) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Something went wrong !",
            showConfirmButton: false,
            timer: 600,
          });
        });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalWithBootstrapButtons.fire({
        title: "Cancelled",
        text: "Your imaginary file is safe :)",
        icon: "error",
      });
    }
  };
  const [money1, setmoney] = useState({
    name: "",
    existense: "False",
    user: 0,
  });
  const find = (e) => {
    const foundElement = belance.find((element) => element === e);
    return foundElement;
  };
  useEffect(() => {
    axios
      .get(Source.getAddress() + "/api/money/", {
        params: {
          delete: "True",
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((res) => {
        setRecords(res.data);
      })
      .catch((err) => {});
  });
  const [edit, setEdit] = useState({
    id: "",
    name: "",
  });
  const [select_user, setselect_user] = useState("");

  const ChangeUser = (e) => {
    if (e.target.value !== "all users") {
      const newData = money.filter((row) => {
        if (typeof row.user_name === "string") {
          return row.user_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        return false;
      });
      setRecords(newData);
      // console.log(e.target.value);
    } else {
      setRecords(money);
    }
    // else{setMoney(accounts)}
    setselect_user(e.target.value);
  };

  const inputRef = useRef(null);
  const updateRef = useRef(null);
  const restore = async (row) => { 
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      let moneyUpdate={
        cach:'',
    existense:'True',
    name
    : 
    "",
    ontransaction
    : 
    '',
    user
    : 
    ''
      };
      moneyUpdate.cach=row.cach;
      moneyUpdate.name=row.name;
      moneyUpdate.ontransaction="False";
      moneyUpdate.user=row.user;
    const uploadData = new FormData();
      for (let key  in row){
        if (row.hasOwnProperty(key)) {
          if(String(key)!=='id' && String(key)!=='user_name' && String(key)!=='existense'){
            uploadData.append(String(key),row[key]);
          }
        }
      }
      uploadData.append('existense','False');
      
      fetch(Source.getAddress() + "/api/money/" + `${row.id}/`, {
        method: "PUT", headers: {
          'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
          // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
        },
        body: uploadData,
      }).then((res)=>{
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Money deleted successfully !",
          showConfirmButton: false,
          timer: 600,
        });
    setRecords(record.filter((a) => a.id !== row.id));    
      }).catch((err)=>{
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something went wrong !",
          showConfirmButton: false,
          timer: 600
        });
      });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalWithBootstrapButtons.fire({
        title: "Cancelled",
        text: "Your imaginary file is safe :)",
        icon: "error",
      });
    } 
      };

  return (
    <div
      className={`continer w-100 h-100 English ${
        settings[0].language === "Persian" && "iransans"
      }`}
      onClick={(e) => {
        if (
          e.target.className == "continer w-100 h-100" ||
          e.target.className == "me-auto ms-auto pt-4 pb-4 h-100 w-50"
        ) {
          setAdd(false);
          setUpdate(false);
          setError(false);
        }
      }}
    >
      <div className="me-auto ms-auto pt-4 pb-4 h-100 resm">
        <div
          class="card card-body m-auto"
          style={{ borderTop: "5px solid #4a5cf2" }}
        >
          <h1 class="text-center rounded m-0 mb-2 p-4 text-light fw-bold bg-info">
            Table Of Money
          </h1>
          <div className="d-flex w-100 h-100 m-auto justify-content-between">
            <select
              id=""
              name="gender"
              onChange={ChangeUser}
              class="form-select h-50 m-auto mb-2"
              style={{ width: "20%" }}
              value={select_user}
            >
              <option value={"all users"} selected>
                All Users
              </option>
              {user.map((item) => (
                <option value={item.name} selected>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <table class="table table-light table-striped">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Name</th>
                <th scope="col">Added by</th>
                <th scope="col">Recover</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              {record &&
                record.map((row, idx) => {
                  return (
                    <tr key={idx}>
                      <th scope="row">{row.id}</th>
                      <td>{row.name}</td>
                      <td>{row.user_name}</td>
                      <td style={{ width: "5%" }}>
                        {(
                          <button
                            style={{
                              border: "none",
                              background: "tranceparent",
                            }}
                            onClick={() => restore(row)}
                          >
                            <img
                              height={"50%"}
                              width={"50%"}
                              src={Restore}
                              style={{
                                background: "transparent",
                              }}
                            />
                          </button>
                        )}
                      </td>
                      <td style={{ width: "5%" }}>
                        {(
                          <button
                            style={{
                              border: "none",
                              background: "tranceparent",
                            }}
                            onClick={() => Delete(row)}
                          >
                            <img
                              height={"55%"}
                              width={"55%"}
                              src={Trash}
                              style={{
                                background: "transparent",
                              }}
                            />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              {/* {source.length>=6 && <a o className="text-primary underlined text-center">{text}</a>} */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
